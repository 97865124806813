import React from "react";
import { css } from "emotion";
import PropTypes from "prop-types";

/** Wrapper for non-button actions
 *
 * For accecibility reasons (and testability) it is strongly recommended to have
 * everything that is clickable inside a button. This is an attempt at creating a
 * "invisible" button wrapper to wrap elements in. Use it as is and apply styling
 * if nescecary
 *
 * @example
 * ```jsx
 * <ActionWrapper onClick={clickHandler}>
 *   <Icon />
 * </ActionWrapper>
 *
 * <ActionWrapper
 *   onClick={clickHandler}
 *   disableFocusStyle={true}
 *   style={{border: "1px red solid"}}
 *   data-test-id="documentation-example-button"
 *   className="some-custom-class"
 * >
 *   <Icon />
 * </ActionWrapper>
 * ```
 */
const ActionWrapper = (props) => {
  return (
    <button
      disabled={props.disabled}
      data-test-id={props["data-test-id"]}
      onClick={props.onClick}
      style={props.style}
      className={`${componentStyles(props)} ${props.className || ""}`}
    >
      {props.children}
    </button>
  );
};

const componentStyles = (props) => css`
  display: block;
  background-color: transparent;
  border: 0px transparent solid;
  outline: 0;
  border-radius: 4px;
  font-size: inherit;
  color: rgba(255, 255, 255, ${props.disabled ? "0.6" : "1"});
  font-weight: inherit;
  cursor: ${props.disabled ? "not-allowed" : "pointer"};

  /* Only applies to non-touch devices */
`;

export default ActionWrapper;

ActionWrapper.propTypes = {
  /** The id to use for testing. Is written as data-test-id="prop" to the DOM */
  "data-test-id": PropTypes.string,
  /** Callback function */
  onClick: PropTypes.func,
  /** Disables active and focus styling. The default active-style is always disabled for non touch-screen devices. Will apply a almost transparent dark background */
  disableFocusStyle: PropTypes.bool,
  /** Override styles */
  style: PropTypes.object,
  /** Apply class'es from parent component */
  className: PropTypes.string,
};
