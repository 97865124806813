import React from "react";
import { useSelector } from "react-redux";
import { Result } from "antd";

import getPageFromId from "../../utilities/get-page-from-id";

const AccessCheck = ({ children, pageId }) => {
  const pages = useSelector((state) => state.pages.pages);
  const page = getPageFromId(pages, pageId);
  const withPage = page ? true : false;

  if (!withPage)
    return (
      <Result
        status="warning"
        subTitle={
          <span>
            Try again or contact us at <a href="mailto:semcompletion@semcomaritime.com">semcompletion@semcomaritime.com</a>
          </span>
        }
        title="You don't have permission to access this page"
      />
    );
  return children;
};

export default AccessCheck;
