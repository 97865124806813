import React from "react";
import { useMediaQuery } from "react-responsive";
import { Select } from "antd";

const DropDown = ({
  className = "",
  disabled = false,
  loading = false,
  name = "",
  onChange = () => {},
  onClear = () => {},
  options = [],
  placeholder = "Select one from the list",
  style = {},
  value = undefined,
  size = "large",
  allowClear = true,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <Select
      allowClear={allowClear}
      className={className}
      disabled={disabled}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      loading={loading}
      listHeight={isTabletOrMobile ? 150 : 256}
      name={name}
      onChange={(e) => onChange({ target: { name, value: e } })}
      onClear={onClear}
      optionFilterProp="children"
      placeholder={loading ? "Loading" : placeholder}
      showSearch
      size={size}
      style={{ width: "100%", ...style }}
      value={loading || options.length === 0 ? null : value || null}
    >
      {options.map((d, i) => (
        <Select.Option disabled={d.disabled} key={i} value={d.value}>
          {d.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default DropDown;
