import React from "react";
import { css } from "emotion";
import colors from "../../style/colors";
import PropTypes from "prop-types";

const ListItem = (props) => {
  const { singleView = false } = props;

  return (
    <div
      style={props.style}
      ref={props.scrollTopRef}
      className={componentStyle(props) + `${props.maxWidth ? " enable-max-width" : ""} ${props.className || ""}`}
      onClick={props.onClick}
      data-test-id={props.dataTestId}
    >
      {/* Number left */}
      {props.itemNumberLeft && <div className="icon-left">{props.itemNumberLeft}</div>}
      {/* Icon left */}
      {props.iconLeft && <div className="icon-left">{props.iconLeft}</div>}
      {/* Image left */}
      {props.imageLeft && <div>{props.imageLeft}</div>}
      <div className={`content` + (props.wrapContent === true ? " wrap" : " nowrap")}>
        <p data-test-id={props.dataTestIdTitle} className="title">
          {props.title}
        </p>
        <p data-test-id={props.dataTestIdSubTitle} className="meta">
          {props.subTitle}
        </p>
        {props.subContent && <div className="subContent">{props.subContent}</div>}
      </div>
      {props.notificationsRight && <div className="icon-right">{props.notificationsRight}</div>}
      {props.iconRight && <div className="icon-right">{props.iconRight}</div>}
      {props.contentRight && <div className="content-right">{props.contentRight}</div>}
    </div>
  );
};

const componentStyle = (props) => css`
  margin-bottom: ${props.index && props.length && props.index === props.length ? "20px" : "-1px"};
  background-color: ${colors.white};
  border-top: 1px solid ${props.singleView ? "transparent" : colors.midGrey};
  border-bottom: 1px solid ${props.singleView ? "transparent" : colors.midGrey};
  border-left: ${props.hasBorderLeft ? `5px solid ${props.borderLeftColor}` : "none"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
  color: ${colors.black};
  flex-shrink: 0;

  &.enable-max-width {
    @media screen and (min-width: ${props.maxWidth}px) {
      max-width: ${props.maxWidth}px;
      margin-left: auto !important;
      margin-right: auto !important;
      border: 1px solid ${colors.midGrey};
    }
  }

  &:active {
    ${props.clickable && `background-color: ${colors.ultraLightGrey};`}
    ${props.clickable && `cursor: pointer;`}
  }

  .icon-left {
    margin-right: ${props.imageLeft === false ? "5px" : "0.5rem"};
    margin-left: ${props.imageLeft === false ? "-5px" : "initial"};
  }
  .icon-right {
    margin-right: 0rem;
  }
  .icon-left,
  .icon-right {
    flex-basis: 20px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
  }
  .content {
    flex: 1 1 100%;
    overflow: hidden;
    min-width: 0;
    padding-right: 0.5rem;

    /* Default option */
    &.nowrap {
      .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .meta {
        color: ${colors.darkGrey};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    /* Wraps content to new line if it overflows */
    &.wrap {
      .title {
        white-space: initial;
      }

      .meta {
        color: ${colors.darkGrey};
        white-space: initial;
      }
    }

    .boldTitle {
      font-weight: bold;
      font-size: 16px;
    }

    .subContent {
      font-size: 12px;
    }

    .subContent ul {
      margin: 0px;
      padding-left: 20px;
      list-style: none;
    }

    .subContent ul li svg {
      margin-right: 10px;
    }
  }
  .image-wrapper {
    width: 30px;
    height: 30px;
    border: 1px #ccc solid;
    border-radius: 50%;
    flex: 0 0 30px;
    margin-right: 0.5rem;
  }
`;

ListItem.propTypes = {
  /**  Used for displaying an icon on the left side */
  iconLeft: PropTypes.object,
  /**  Used for displaying an image on the left side */
  imageLeft: PropTypes.object,
  /**  Used for displaying an icon on the right side */
  iconRight: PropTypes.object,
  /**  Used for displaying text-based content on the right side */
  contentRight: PropTypes.string,
  /** Content main title, for related info, use subtitle Prop */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Descriptive textblock related to title prop */
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** used for deciding if content should wrap */
  wrapContent: PropTypes.bool,
};
export default ListItem;
