import React from "react";
import { css } from "emotion";
import colors from "../../style/colors";
import { connect } from "react-redux";
import PropTypes from "prop-types";
/** Component used for displaying an ongoing, unfinished action. It shows a contious spinner all the time,
 *  so you need to have condition that handles this, otherwise, the spinner just confuses the user */
const Spinner = (props) => (
  <div style={props.style} className={style(props)} data-test-id={props["data-test-id"]}>
    <div className="spinner" />
    {props.title && <p className="meta">{props.title}</p>}
  </div>
);

const style = (props) => css`
  .spinner {
    width: ${props.size || 38}px;
    height: ${props.size || 38}px;
    border-radius: 50%;
    border: 3px solid #000;
    border-color: ${props.color || props.primaryColor} ${props.color || props.primaryColor}
      ${props.color || props.primaryColor} rgba(0, 0, 0, 0);
    margin: 0 auto 0.5rem auto;
    animation: spin 0.7s infinite linear;
  }
  text-align: center;
  p.meta {
    color: ${colors.darkGrey};
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const mapStateToProps = (state) => ({
  primaryColor: state.appConfig.primaryColor,
});

Spinner.propTypes = {
  /** Used for overiding primaryColor, in case you need a spinner in a different color than primaryColor */
  color: PropTypes.string,
  /** The applications primary color */
  primaryColor: PropTypes.string,
};
export default connect(mapStateToProps)(Spinner);
