// Side menu
export const OPEN_SIDE_MENU = "OPEN_SIDE_MENU";
export const CLOSE_SIDE_MENU = "CLOSE_SIDE_MENU";

// Language
export const GET_LANGUAGE = "GET_LANGUAGE";
export const GET_LANGUAGE_SUCCESS = "GET_LANGUAGE_SUCCESS";
export const GET_LANGUAGE_FAILURE = "GET_LANGUAGE_FAILURE";

// Pages
export const GET_PAGES = "GET_PAGES";
export const GET_PAGES_SUCCESS = "GET_PAGES_SUCCESS";
export const GET_PAGES_FAILURE = "GET_PAGES_FAILURE";
export const SET_TRANSITION_DIRECTION = "SET_TRANSITION_DIRECTION";

// Paper
export const DOCUMENTS = {
  CANCEL: {
    REQUEST: "CANCEL_DOCUMENTS_PENDING",
    SUCCESS: "CANCEL_DOCUMENTS_SUCCESS",
    FAILURE: "CANCEL_DOCUMENTS_FAILURE",
  },
  EDIT: {
    REQUEST: "EDIT_DOCUMENTS_PENDING",
    SUCCESS: "EDIT_DOCUMENTS_SUCCESS",
    FAILURE: "EDIT_DOCUMENTS_FAILURE",
  },
  FETCH: {
    REQUEST: "FETCH_DOCUMENTS_PENDING",
    SUCCESS: "FETCH_DOCUMENTS_SUCCESS",
    FAILURE: "FETCH_DOCUMENTS_FAILURE",
  },
  RESET: "RESET_DOCUMENTS",
  SAVE: {
    REQUEST: "SAVE_DOCUMENTS_PENDING",
    SUCCESS: "SAVE_DOCUMENTS_SUCCESS",
    FAILURE: "SAVE_DOCUMENTS_FAILURE",
  },
  SETVIEW: "SET_DOCUMENTS_VIEW",
  SETFORM: "SET_FORM",
};

export const WELDLOGS = {
  EDIT: {
    REQUEST: "EDIT_WELDLOGS_PENDING",
    SUCCESS: "EDIT_WELDLOGS_SUCCESS",
    FAILURE: "EDIT_WELDLOGS_FAILURE",
  },
  FETCH: {
    REQUEST: "FETCH_WELDLOGS_PENDING",
    SUCCESS: "FETCH_WELDLOGS_SUCCESS",
    FAILURE: "FETCH_WELDLOGS_FAILURE",
  },
  RESET: "RESET_WELDLOGS",
  SAVE: {
    REQUEST: "SAVE_WELDLOGS_PENDING",
    SUCCESS: "SAVE_WELDLOGS_SUCCESS",
    FAILURE: "SAVE_WELDLOGS_FAILURE",
  },
  SETFORM: "SET_FORM",
  SETFORMPROP: "SET_FORM_PROP",
};

export const REDMARKLOGS = {
  EDIT: {
    REQUEST: "EDIT_REDMARKLOGS_PENDING",
    SUCCESS: "EDIT_REDMARKLOGS_SUCCESS",
    FAILURE: "EDIT_REDMARKLOGS_FAILURE",
  },
  FETCH: {
    REQUEST: "FETCH_REDMARKLOGS_PENDING",
    SUCCESS: "FETCH_REDMARKLOGS_SUCCESS",
    FAILURE: "FETCH_REDMARKLOGS_FAILURE",
  },
  RESET: "RESET_REDMARKLOGS",
  SAVE: {
    REQUEST: "SAVE_REDMARKLOGS_PENDING",
    SUCCESS: "SAVE_REDMARKLOGS_SUCCESS",
    FAILURE: "SAVE_REDMARKLOGS_FAILURE",
  },
  SETFORM: "SET_FORM",
  SETFORMPROP: "SET_FORM_PROP",
};

export const WIREDIAGRAM = {
  SETISEDITING: "SET_IS_EDITING",
};

export const TESTSHEET = {
  SETISSAVEDASDRAFT: "SET_IS_SAVED_AS_DRAFT",
  ADDISSAVEDASDRAFT: "ADD_IS_SAVED_AS_DRAFT",
  REMOVEISSAVEDASDRAFT: "REMOVE_IS_SAVED_AS_DRAFT",
  SETTESTSHEETTITLE: "SET_TEST_SHEET_TITLE",
};

export const MCTASKS = {
  SETNEWTASK: "SET_NEW_TASK",
};

export const MCEQUIPMENTTASKS = {
  SETNEWEQUIPMENTTASK: "SET_NEW_EQUIPMENT_TASK",
};

export const LIBRARY = {
  SETFOLDERS: "SET_FOLDERS",
  SETlIBRARYFILEQUERY: "SET_LIBRARY_FILE_QUERY",
  SETLIBRARYFOLDERQUERY: "SET_LIBRARY_FOLDER_QUERY",
  SETLIBRARYURLQUERY: "SET_LIBRARY_URL_QUERY",
  SETMANAGELIBRARYFILEQUERY: "SET_MANAGE_LIBRARY_FILE_QUERY",
  SETMANAGELIBRARYFOLDERQUERY: "SET_MANAGE_LIBRARY_FOLDER_QUERY",
  SETMANAGELIBRARYURLQUERY: "SET_MANAGE_LIBRARY_URL_QUERY",
  SETSELECTEDFOLDER: "SET_SELECTED_FOLDER",
  SETSELECTEDMANAGELIBRARYPATH: "SET_SELECTED_MANAGE_LIBRARY_PATH",
  SETSELECTEDLIBRARYPATH: "SET_SELECTED_LIBRARY_PATH",
  SETURLSUFFIX: "SET_URL_SUFFIX",
};

export const PDF = {
  SETHASCHANGES: "SET_HAS_CHANGES",
  SETPDFVIEWER: "SET_PDF_VIEWER",
  SETREADONLY: "SET_READ_ONLY",
};

export const SET_TOOL = "SET_TOOL";

// Auth
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";
export const AD_SIGN_IN_FAILURE = "AD_SIGN_IN_FAILURE";
export const AD_SIGN_IN_FAILURE_HANDLED = "AD_SIGN_IN_FAILURE_HANDLED";
export const SIGN_IN_FAILURE_HANDLED = "SIGN_IN_FAILURE_HANDLED";
export const SIGN_OUT = "SIGN_OUT";
export const CHECK_TOKEN = "CHECK_TOKEN";
export const CHECK_TOKEN_VALID = "CHECK_TOKEN_VALID";
export const CHECK_TOKEN_INVALID = "CHECK_TOKEN_INVALID";
export const CHECK_TOKEN_SERVICE_UNAVAILABLE = "CHECK_TOKEN_SERVICE_UNAVAILABLE";
export const RENEW_TOKEN = "RENEW_TOKEN";
export const RENEW_TOKEN_SUCCESS = "RENEW_TOKEN_SUCCESS";
export const RENEW_TOKEN_FAILURE = "RENEW_TOKEN_FAILURE";
export const RENEW_PASSWORD_SUCCESS = "RENEW_PASSWORD_SUCCESS";
export const RENEW_PASSWORD_FAILED = "RENEW_PASSWORD_FAILED";
export const RENEW_PASSWORD_HANDLED = "RENEW_PASSWORD_HANDLED";

// App Config
export const GET_APP_CONFIG = "GET_APP_CONFIG";
export const GET_APP_CONFIG_SUCCESS = "GET_APP_CONFIG_SUCCESS";
export const GET_APP_CONFIG_FAILURE = "GET_APP_CONFIG_FAILURE";
export const SET_APP_LANGUAGE = "SET_APP_LANGUAGE";
export const CLEAR_APP_CONFIG_LOGIN_TYPES = "CLEAR_APP_CONFIG_LOGIN_TYPES";

// UI
// -> Context menu
export const SHOW_CONTEXT_MENU = "SHOW_CONTEXT_MENU";
export const HIDE_CONTEXT_MENU = "HIDE_CONTEXT_MENU";
export const CLEAR_CONTEXT_MENU_ACTIONS = "CLEAR_CONTEXT_MENU_ACTIONS";

// -> Dialog
export const SHOW_DIALOG = "SHOW_DIALOG";
export const HIDE_DIALOG = "HIDE_DIALOG";
export const CLEAR_DIALOG = "CLEAR_DIALOG";

// -> Lightbox
export const SHOW_LIGHT_BOX = "SHOW_LIGHT_BOX";
export const HIDE_LIGHT_BOX = "HIDE_LIGHT_BOX";

// -> Modal page
export const SHOW_MODAL_PAGE = "SHOW_MODAL_PAGE";
export const SHOW_MODAL_PAGE_2ND = "SHOW_MODAL_PAGE_2ND";
export const UPDATE_MODAL_PAGE_CONTENT = "UPDATE_MODAL_PAGE_CONTENT";
export const HIDE_MODAL_PAGE = "HIDE_MODAL_PAGE";
export const HIDE_MODAL_PAGE_2ND = "HIDE_MODAL_PAGE_2ND";
export const CLEAR_MODAL_PAGE = "CLEAR_MODAL_PAGE";
export const CLEAR_MODAL_PAGE_2ND = "CLEAR_MODAL_PAGE_2ND";

// -> Tab bar navigation
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";

// -> Work hour modal
export const TOGGLE_WORK_HOUR_MODAL = "TOGGLE_WORK_HOUR_MODAL";
/******************************* SEMCO  ****************************/
/* If there are too many of theese find a way to seperate them from the main bundle */
export const SC_SET_SELECTED_PROJECT = "SC_SET_SELECTED_PROJECT";
export const SC_SET_SELECTED_PROJECT_AREA = "SC_SET_SELECTED_PROJECT_AREA";
