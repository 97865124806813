import React, { memo } from "react";
import { css } from "emotion";
import { Space } from "antd";

const FormItem = ({ children, label = "", required = false }) => {
  return (
    <Space className={container()} direction="vertical" size={2}>
      <label className="label">
        {label}
        {required && <span className="required">*</span>}
      </label>
      {children}
    </Space>
  );
};

const container = (props) => css`
  width: 100%;

  .required {
    color: red;
  }
`;

export default memo(FormItem);
