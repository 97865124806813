import React, { memo, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { notification } from "antd";
import { css } from "emotion";
import { isEqual, startCase } from "lodash";
import PSPDFKit from "pspdfkit";

import { setHasChanges, setPdfViewer } from "../../actions/pdfActions";

import req from "../../utilities/request-utility";

const toolbarItems = [
  "sidebar-annotations",
  "print",
  "export-pdf",
  "zoom-in",
  "zoom-out",
  "zoom-mode",
  "spacer",
  "multi-annotations-selection",
  "callout",
];

const customFontSubstitutions = [
  {
    pattern: "Open Sans",
    target: "Arial",
  },
  {
    pattern: "sans-serif",
    target: "Arial",
  },
];

let config = {
  baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
  dynamicFonts: `${window.location.protocol}//${window.location.host}${process.env.PUBLIC_URL}/pspdfkit-fonts/dynamicFonts/fonts.json`,
  fontSubstitutions: customFontSubstitutions,
  initialViewState: new PSPDFKit.ViewState({ readOnly: true }),
  isEditableAnnotation: (annotations) => false,
  styleSheets: [`${process.env.PUBLIC_URL}/style/pspdfkit.css`],
  toolbarItems: PSPDFKit.defaultToolbarItems.filter((item) => !toolbarItems.includes(item.type)),
};

if (process.env.REACT_APP_PDF_LICENSE_KEY !== null && process.env.REACT_APP_PDF_LICENSE_KEY !== undefined)
  config = { ...config, licenseKey: process.env.REACT_APP_PDF_LICENSE_KEY };

PSPDFKit.preloadWorker(config);

PSPDFKit.Options.HIGHLIGHT_COLOR_PRESETS = [
  {
    color: PSPDFKit.Color.RED,
    localization: { id: "red", defaultMessage: "Red" },
  },
  {
    color: PSPDFKit.Color.GREEN,
    localization: { id: "green", defaultMessage: "Green" },
  },
  {
    color: PSPDFKit.Color.BLUE,
    localization: { id: "blue", defaultMessage: "Blue" },
  },
  {
    color: PSPDFKit.Color.YELLOW,
    localization: { id: "yellow", defaultMessage: "Yellow" },
  },
];

const PDFViewer = ({ bookmarks = [], file, fileId, module }) => {
  const containerRef = useRef(null);

  const dispatch = useDispatch();

  const { pdfViewer, readOnly } = useSelector(({ pdf }) => ({ pdfViewer: pdf.pdfViewer, readOnly: pdf.readOnly }), isEqual);

  useEffect(() => {
    const container = containerRef.current;
    const newlyAddedAnnotationIds = [];

    let instance = null;
    let previousViewState = null;

    const onCreateBookmark = async (bookmark) => {
      try {
        const existingBookmark = bookmarks.find((d) => d.bookmarkId === bookmark._tail.array[0].id);
        if (existingBookmark) return;

        const { data } = await req().post(`semcompletion/${module}/${fileId}/bookmark`, {
          bookmarkId: bookmark._tail.array[0].id,
          bookmarkName: bookmark._tail.array[0].name,
          bookmarkAction: "Go To",
          bookmarkPageIndex: bookmark._tail.array[0].action.pageIndex,
        });

        notification.success({ duration: 7, message: "SUCCESS", description: data });
      } catch (error) {
        notification.error({ duration: 7, message: "FAILED", description: "Something went wrong while adding a bookmark" });
      }
    };

    const onUpdateBookmark = async (bookmark) => {
      try {
        const bookmarkId = bookmarks.find((d) => d.bookmarkId === bookmark._tail.array[0].id).id;
        if (!bookmarkId) return;

        const { data } = await req().put(`semcompletion/${module}/${fileId}/bookmark/${bookmarkId}`, {
          bookmarkName: bookmark._tail.array[0].name,
          bookmarkPageIndex: bookmark._tail.array[0].action.pageIndex,
        });

        notification.success({ duration: 7, message: "SUCCESS", description: data });
      } catch (error) {
        notification.error({
          duration: 7,
          message: "FAILED",
          description: "Something went wrong while updating a bookmark",
        });
      }
    };

    const onDeleteBookmark = async (bookmark) => {
      try {
        const bookmarkId = bookmarks.find((d) => d.bookmarkId === bookmark._tail.array[0].id).id;
        if (!bookmarkId) return;

        const { data } = await req().delete(`semcompletion/${module}/${fileId}/bookmark/${bookmarkId}`);

        notification.success({ duration: 7, message: "SUCCESS", description: data });
      } catch (error) {
        notification.error({
          duration: 7,
          message: "FAILED",
          description: "Something went wrong while deleting a bookmark",
        });
      }
    };

    const onAnnotationChange = () => {
      instance.setToolbarItems(
        instance.toolbarItems.map((item) => (item.type === "custom" ? { ...item, selected: false } : item))
      );

      instance.setAnnotationToolbarItems((annotation, { defaultAnnotationToolbarItems }) => {
        if (
          isMobile &&
          annotation instanceof PSPDFKit.Annotations.InkAnnotation &&
          annotation.lineWidth === 1 &&
          annotation.opacity === 1
        )
          return defaultAnnotationToolbarItems.filter((d) => d.type === "delete" || d.type === "spacer");

        if (
          isMobile &&
          (annotation instanceof PSPDFKit.Annotations.LineAnnotation ||
            annotation instanceof PSPDFKit.Annotations.RectangleAnnotation ||
            annotation instanceof PSPDFKit.Annotations.EllipseAnnotation)
        )
          return defaultAnnotationToolbarItems.filter(
            (d) =>
              d.type !== "stroke-color" &&
              d.type !== "fill-color" &&
              d.type !== "line-width" &&
              d.type !== "annotation-note" &&
              d.type !== "opacity" &&
              d.type !== "linecaps-dasharray" &&
              d.type !== "line-style" &&
              d.type !== "annotation-note"
          );

        if (!isMobile && annotation instanceof PSPDFKit.Annotations.InkAnnotation) {
          const redDrawing = instance.contentDocument.querySelector('[title="Red Drawing"]');
          const greenDrawing = instance.contentDocument.querySelector('[title="Green Drawing"]');
          const blueDrawing = instance.contentDocument.querySelector('[title="Blue Drawing"]');

          if (
            redDrawing.classList.contains("PSPDFKit-Toolbar-Button-active") ||
            greenDrawing.classList.contains("PSPDFKit-Toolbar-Button-active") ||
            blueDrawing.classList.contains("PSPDFKit-Toolbar-Button-active") ||
            annotation.customData?.isCustomInk
          ) {
            return defaultAnnotationToolbarItems.filter(
              (d) => d.type !== "annotation-note" && d.type !== "stroke-color" && d.type !== "fill-color"
            );
          }
        }

        return defaultAnnotationToolbarItems.filter((d) => d.type !== "annotation-note");
      });
    };

    const onAnnotationSelectionChange = (annotation) => {
      if (
        annotation instanceof PSPDFKit.Annotations.InkAnnotation ||
        annotation instanceof PSPDFKit.Annotations.TextAnnotation ||
        annotation instanceof PSPDFKit.Annotations.LineAnnotation ||
        annotation instanceof PSPDFKit.Annotations.EllipseAnnotation ||
        annotation instanceof PSPDFKit.Annotations.RectangleAnnotation ||
        annotation instanceof PSPDFKit.Annotations.PolygonAnnotation ||
        annotation instanceof PSPDFKit.Annotations.PolylineAnnotation
      ) {
        if (!newlyAddedAnnotationIds.includes(annotation.id)) newlyAddedAnnotationIds.push(annotation.id);
        instance.setIsEditableAnnotation((anon) => newlyAddedAnnotationIds.includes(anon.id));
      }
    };

    const onAnnotationWillChange = async (event) => {
      const annotation = event.annotations.get(0);

      const inputBlurListener = (e) => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
      };

      const inputEl = instance.contentDocument.querySelector("[contenteditable='true'");

      if (
        annotation instanceof PSPDFKit.Annotations.TextAnnotation &&
        event.reason === PSPDFKit.AnnotationsWillChangeReason.TEXT_EDIT_START
      )
        inputEl.addEventListener("blur", inputBlurListener);

      if (
        annotation instanceof PSPDFKit.Annotations.TextAnnotation &&
        event.reason === PSPDFKit.AnnotationsWillChangeReason.TEXT_EDIT_END
      ) {
        inputEl.removeEventListener("blur", inputBlurListener);

        if (annotation.text !== "") return dispatch(setHasChanges(true));

        const annotationIndex = newlyAddedAnnotationIds.findIndex((d) => d === annotation.id);
        newlyAddedAnnotationIds.splice(annotationIndex, 1);

        dispatch(setHasChanges(false));
      }

      if (
        (annotation instanceof PSPDFKit.Annotations.InkAnnotation ||
          annotation instanceof PSPDFKit.Annotations.LineAnnotation ||
          annotation instanceof PSPDFKit.Annotations.RectangleAnnotation ||
          annotation instanceof PSPDFKit.Annotations.EllipseAnnotation) &&
        event.reason === PSPDFKit.AnnotationsWillChangeReason.DRAW_END
      )
        dispatch(setHasChanges(true));

      if (
        (annotation instanceof PSPDFKit.Annotations.InkAnnotation ||
          annotation instanceof PSPDFKit.Annotations.LineAnnotation ||
          annotation instanceof PSPDFKit.Annotations.RectangleAnnotation ||
          annotation instanceof PSPDFKit.Annotations.EllipseAnnotation) &&
        event.reason === PSPDFKit.AnnotationsWillChangeReason.DELETE_END
      ) {
        const annotationIndex = newlyAddedAnnotationIds.findIndex((d) => d === annotation.id);
        newlyAddedAnnotationIds.splice(annotationIndex, 1);

        if (newlyAddedAnnotationIds.length === 0) dispatch(setHasChanges(false));
      }
    };

    const onKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && (event.keyCode === 66 || event.keyCode === 73 || event.keyCode === 85)) {
        event.preventDefault();
        event.stopImmediatePropagation();
        console.log("Text shortcut detected");
      }
    };

    const onViewStateChange = (viewState) => {
      const selectedAnnotations = instance.getSelectedAnnotations();

      if (
        viewState.interactionMode !== previousViewState &&
        viewState.interactionMode !== null &&
        viewState.interactionMode !== "INK_ERASER" &&
        viewState.interactionMode !== "TEXT" &&
        isMobile
      ) {
        setTimeout(() => {
          if (selectedAnnotations) {
            selectedAnnotations.map((a) => {
              if (
                (a instanceof PSPDFKit.Annotations.InkAnnotation && a.lineWidth === 1 && a.opacity === 1) ||
                a instanceof PSPDFKit.Annotations.LineAnnotation ||
                a instanceof PSPDFKit.Annotations.RectangleAnnotation ||
                a instanceof PSPDFKit.Annotations.EllipseAnnotation
              ) {
                const annotationToolbar = instance.contentDocument.querySelector(`.PSPDFKit-Annotation-Toolbar`);
                annotationToolbar.style.display = "none";
              }
            });
          }
        }, 50);
      }

      if (viewState.interactionMode !== previousViewState && viewState.interactionMode === null && isMobile) {
        setTimeout(() => {
          const annotationToolbar = instance.contentDocument.querySelector(`.PSPDFKit-Annotation-Toolbar`);
          annotationToolbar.style.display = "block";
        }, 50);
      }

      if (
        viewState.interactionMode !== previousViewState &&
        previousViewState === "INK" &&
        viewState.interactionMode === null
      ) {
        instance.setToolbarItems((items) => {
          return items.map((item) => {
            if (
              item.type === "custom" &&
              (item.id === "red-drawing" ||
                item.id === "green-drawing" ||
                item.id === "blue-drawing" ||
                item.id === "highlighter")
            ) {
              if (!item.selected) {
                const button = instance.contentDocument.querySelector(`[title="${startCase(item.id)}"]`);

                button.classList.remove("PSPDFKit-Toolbar-Button-active");
                button.classList.remove("PSPDFKit-Tool-Button-active");

                switch (item.id) {
                  case "red-drawing":
                    button.innerHTML = `
                      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.75" d="M1.70679 20.568L3.47479 22.335L2.05979 23.75C1.87226 23.9375 1.61795 24.0428 1.35279 24.0428C1.08762 24.0428 0.833314 23.9375 0.645787 23.75L0.292786 23.396C0.105315 23.2085 0 22.9542 0 22.689C0 22.4238 0.105315 22.1695 0.292786 21.982L1.70679 20.568Z" fill="#F82400"/>
                        <path d="M8.92479 5.92999L8.21479 6.62999C3.26479 11.63 3.62479 16.89 3.62479 16.89L2.04479 18.48C1.92838 18.5961 1.83602 18.734 1.773 18.8859C1.70999 19.0378 1.67755 19.2006 1.67755 19.365C1.67755 19.5294 1.70999 19.6922 1.773 19.8441C1.83602 19.9959 1.92838 20.1339 2.04479 20.25L3.79479 22C3.9109 22.1164 4.04884 22.2088 4.2007 22.2718C4.35256 22.3348 4.51537 22.3672 4.67979 22.3672C4.8442 22.3672 5.00701 22.3348 5.15887 22.2718C5.31073 22.2088 5.44867 22.1164 5.56479 22L7.15479 20.41C7.15479 20.41 12.4648 20.77 17.4148 15.82L18.1148 15.11" stroke="black" stroke-miterlimit="10" stroke-linecap="round"/>
                        <path opacity="0.33" d="M16.7068 15.826L8.2218 7.341L6.8078 8.755L15.2928 17.24L16.7068 15.826Z" fill="#F82400"/>
                        <path opacity="0.5" d="M6.93981 19.931L4.11081 17.103L3.40381 17.81L6.23281 20.638L6.93981 19.931Z" fill="#F82400"/>
                        <circle cx="18.0448" cy="6" r="6" fill="#F82400"/>
                      </svg>
                    `;
                    break;
                  case "green-drawing":
                    button.innerHTML = `
                      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.75" d="M1.70679 20.568L3.47479 22.335L2.05979 23.75C1.87226 23.9375 1.61795 24.0428 1.35279 24.0428C1.08762 24.0428 0.833314 23.9375 0.645787 23.75L0.292786 23.396C0.105315 23.2085 0 22.9542 0 22.689C0 22.4238 0.105315 22.1695 0.292786 21.982L1.70679 20.568Z" fill="#6DB000"/>
                        <path d="M8.92479 5.92999L8.21479 6.62999C3.26479 11.63 3.62479 16.89 3.62479 16.89L2.04479 18.48C1.92838 18.5961 1.83602 18.734 1.773 18.8859C1.70999 19.0378 1.67755 19.2006 1.67755 19.365C1.67755 19.5294 1.70999 19.6922 1.773 19.8441C1.83602 19.9959 1.92838 20.1339 2.04479 20.25L3.79479 22C3.9109 22.1164 4.04884 22.2088 4.2007 22.2718C4.35256 22.3348 4.51537 22.3672 4.67979 22.3672C4.8442 22.3672 5.00701 22.3348 5.15887 22.2718C5.31073 22.2088 5.44867 22.1164 5.56479 22L7.15479 20.41C7.15479 20.41 12.4648 20.77 17.4148 15.82L18.1148 15.11" stroke="black" stroke-miterlimit="10" stroke-linecap="round"/>
                        <path opacity="0.33" d="M16.7068 15.826L8.2218 7.341L6.8078 8.755L15.2928 17.24L16.7068 15.826Z" fill="#6DB000"/>
                        <path opacity="0.5" d="M6.93981 19.931L4.11081 17.103L3.40381 17.81L6.23281 20.638L6.93981 19.931Z" fill="#6DB000"/>
                        <circle cx="18.0448" cy="6" r="6" fill="#6DB000"/>
                      </svg>
                    `;
                    break;
                  case "blue-drawing":
                    button.innerHTML = `
                      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.75" d="M1.70679 20.568L3.47479 22.335L2.05979 23.75C1.87226 23.9375 1.61795 24.0428 1.35279 24.0428C1.08762 24.0428 0.833314 23.9375 0.645787 23.75L0.292786 23.396C0.105315 23.2085 0 22.9542 0 22.689C0 22.4238 0.105315 22.1695 0.292786 21.982L1.70679 20.568Z" fill="#2293FB"/>
                        <path d="M8.92479 5.92999L8.21479 6.62999C3.26479 11.63 3.62479 16.89 3.62479 16.89L2.04479 18.48C1.92838 18.5961 1.83602 18.734 1.773 18.8859C1.70999 19.0378 1.67755 19.2006 1.67755 19.365C1.67755 19.5294 1.70999 19.6922 1.773 19.8441C1.83602 19.9959 1.92838 20.1339 2.04479 20.25L3.79479 22C3.9109 22.1164 4.04884 22.2088 4.2007 22.2718C4.35256 22.3348 4.51537 22.3672 4.67979 22.3672C4.8442 22.3672 5.00701 22.3348 5.15887 22.2718C5.31073 22.2088 5.44867 22.1164 5.56479 22L7.15479 20.41C7.15479 20.41 12.4648 20.77 17.4148 15.82L18.1148 15.11" stroke="black" stroke-miterlimit="10" stroke-linecap="round"/>
                        <path opacity="0.33" d="M16.7068 15.826L8.2218 7.341L6.8078 8.755L15.2928 17.24L16.7068 15.826Z" fill="#2293FB"/>
                        <path opacity="0.5" d="M6.93981 19.931L4.11081 17.103L3.40381 17.81L6.23281 20.638L6.93981 19.931Z" fill="#2293FB"/>
                        <circle cx="18.0448" cy="6" r="6" fill="#2293FB"/>
                      </svg>
                    `;
                    break;
                  case "highlighter":
                    button.innerHTML = `
                      <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg" focusable="false">
                        <path opacity=".25" d="M13.02 16.6l-4.96-4.95 3.05-6.86 8.76 8.77-6.85 3.04z" fill="currentColor"></path>
                        <path d="M12 2.81l-.46.47a3 3 0 00-.9 1.72 11.67 11.67 0 01-2.93 6.3l-1.77 1.77a1 1 0 000 1.41l4.25 4.24a1 1 0 001.41 0L13.37 17a11.64 11.64 0 016.34-3 3 3 0 001.68-.85l.46-.47" stroke="currentColor" stroke-miterlimit="10" fill="none" stroke-linecap="round"></path>
                        <path opacity=".33" d="M14.075 16.242L8.42 10.585l-.707.707 5.656 5.657.707-.707z" fill="currentColor"></path>
                        <path opacity=".66" d="M2.34 18.44l3.805-3.816 3.816 3.816-2.471 2.48a.89.89 0 01-1 .2l-3.92-1.58a.68.68 0 01-.414-.488.68.68 0 01.184-.612z" fill="currentColor"></path>
                        <path opacity=".66" d="M2.521 18.59h.001l3.628-3.64 3.463 3.463-2.295 2.303h0l-.004.004a.64.64 0 01-.719.144h0l-.007-.003-3.92-1.58h0l-.003-.001a.43.43 0 01-.259-.306.43.43 0 01.115-.384z" stroke="currentColor" fill="none" stroke-width=".5"></path>
                        <path opacity=".66" d="M2.17 18.73a.69.69 0 00.4.81l3.98 1.605a.874.874 0 00.65-.025 2.94 2.94 0 00-.9-2.71c-1.09-.83-2.71-.65-4.13.32z" fill="currentColor"></path>
                      </svg>
                    `;
                    break;
                }
              }
            }

            return item;
          });
        });
      }

      previousViewState = viewState.interactionMode;
    };

    const onAnnotationCreated = (createdAnnotation) => {
      createdAnnotation.map(async (a) => {
        if (a instanceof PSPDFKit.Annotations.TextAnnotation) {
          const updatedAnnotation = a.set(
            "boundingBox",
            new PSPDFKit.Geometry.Rect({
              left: a.boundingBox.left + 1,
              top: a.boundingBox.top + 1,
              width: a.boundingBox.width,
              height: a.boundingBox.height,
            })
          );

          await instance.update(updatedAnnotation);
        }

        const redDrawing = instance.contentDocument.querySelector('[title="Red Drawing"]');
        const greenDrawing = instance.contentDocument.querySelector('[title="Green Drawing"]');
        const blueDrawing = instance.contentDocument.querySelector('[title="Blue Drawing"]');

        if (
          a instanceof PSPDFKit.Annotations.InkAnnotation &&
          (redDrawing.classList.contains("PSPDFKit-Toolbar-Button-active") ||
            greenDrawing.classList.contains("PSPDFKit-Toolbar-Button-active") ||
            blueDrawing.classList.contains("PSPDFKit-Toolbar-Button-active"))
        ) {
          const updatedAnnotation = a.set("customData", { isCustomInk: true });
          await instance.update(updatedAnnotation);
        }
      });
    };

    const onPageIndexChange = async (pageIndex) => localStorage.setItem("pdf-page-index", pageIndex);

    (async () => {
      try {
        PSPDFKit.unload(container);

        let pdfConfig = {
          ...config,
          container,
          document: file,
        };

        instance = await PSPDFKit.load(pdfConfig);

        instance.addEventListener("bookmarks.create", onCreateBookmark);
        instance.addEventListener("bookmarks.update", onUpdateBookmark);
        instance.addEventListener("bookmarks.delete", onDeleteBookmark);
        instance.addEventListener("annotations.change", onAnnotationChange);
        instance.addEventListener("annotations.create", onAnnotationCreated);
        instance.addEventListener("annotations.willChange", onAnnotationWillChange);
        instance.addEventListener("annotationSelection.change", onAnnotationSelectionChange);
        instance.addEventListener("viewState.change", onViewStateChange);
        instance.addEventListener("viewState.currentPageIndex.change", onPageIndexChange);

        instance.contentDocument.addEventListener("keydown", onKeyDown);

        instance.setAnnotationPresets((presets) => {
          presets.ink = {
            ...presets.ink,
            strokeColor: new PSPDFKit.Color({ r: 255, g: 0, b: 0 }),
            strokeWidth: 1,
            lineWidth: 1,
            inkEraserWidth: 5,
          };

          presets.text = {
            ...presets.text,
            fontColor: new PSPDFKit.Color({ r: 255, g: 0, b: 0 }),
            fontSize: 10,
          };

          presets.line = {
            ...presets.line,
            strokeColor: new PSPDFKit.Color({ r: 255, g: 0, b: 0 }),
            strokeWidth: 1,
          };

          presets.arrow = {
            ...presets.arrow,
            strokeColor: new PSPDFKit.Color({ r: 255, g: 0, b: 0 }),
            strokeWidth: 1,
          };

          presets.rectangle = {
            ...presets.rectangle,
            strokeColor: new PSPDFKit.Color({ r: 255, g: 0, b: 0 }),
            strokeWidth: 1,
          };

          presets.ellipse = {
            ...presets.ellipse,
            strokeColor: new PSPDFKit.Color({ r: 255, g: 0, b: 0 }),
            strokeWidth: 1,
          };

          presets.polygon = {
            ...presets.polygon,
            strokeColor: new PSPDFKit.Color({ r: 255, g: 0, b: 0 }),
            strokeWidth: 1,
          };

          presets["cloudy-polygon"] = {
            ...presets["cloudy-polygon"],
            strokeColor: new PSPDFKit.Color({ r: 255, g: 0, b: 0 }),
            strokeWidth: 1,
          };

          presets.polyline = {
            ...presets.polyline,
            strokeColor: new PSPDFKit.Color({ r: 255, g: 0, b: 0 }),
            strokeWidth: 1,
          };

          presets.highlighter = {
            ...presets.highlighter,
            strokeColor: PSPDFKit.Color.GREEN,
            lineWidth: 17,
            opacity: 0.5,
          };

          presets["text-highlighter"] = {
            ...presets["text-highlighter"],
            outlineColor: PSPDFKit.Color.GREEN,
            color: PSPDFKit.Color.GREEN,
            opacity: 0.5,
          };

          return presets;
        });

        dispatch(setPdfViewer(instance));
      } catch (error) {
        console.log("%c Line:420 🍭 error", "color:#33a5ff", error);
        notification.error({ duration: 7, message: "FAILED", description: "Something went wrong while previewing the PDF" });
      }
    })();

    return () => {
      if (instance) {
        instance.removeEventListener("bookmarks.create", onCreateBookmark);
        instance.removeEventListener("bookmarks.update", onUpdateBookmark);
        instance.removeEventListener("bookmarks.delete", onDeleteBookmark);
        instance.removeEventListener("annotations.change", onAnnotationChange);
        instance.removeEventListener("annotations.create", onAnnotationCreated);
        instance.removeEventListener("annotations.willChange", onAnnotationWillChange);
        instance.removeEventListener("annotationSelection.change", onAnnotationSelectionChange);
        instance.removeEventListener("viewState.change", onViewStateChange);
        instance.removeEventListener("viewState.currentPageIndex.change", onPageIndexChange);

        instance.contentDocument.removeEventListener("keydown", onKeyDown);

        localStorage.removeItem("pdf-page-index");
      }

      PSPDFKit.unload(container);
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (pdfViewer) {
        const existingBookmarks = await pdfViewer.getBookmarks();

        if (bookmarks.length > 0 && !existingBookmarks._tail)
          bookmarks.map((d) => {
            const bookmark = new PSPDFKit.Bookmark({
              name: d.bookmarkName,
              id: d.bookmarkId,
              action: new PSPDFKit.Actions.GoToAction({ pageIndex: d.bookmarkPageIndex }),
            });

            pdfViewer.create(bookmark);
          });
      }
    })();
  }, [pdfViewer, bookmarks]);

  useEffect(() => {
    try {
      if (pdfViewer) {
        let toolbarItems = readOnly
          ? [
              "sidebar-annotations",
              "print",
              "export-pdf",
              "zoom-in",
              "zoom-out",
              "zoom-mode",
              "multi-annotations-selection",
              "callout",
            ]
          : [
              "sidebar-annotations",
              "print",
              "export-pdf",
              "image",
              "note",
              "stamp",
              "link",
              "zoom-in",
              "zoom-out",
              "zoom-mode",
              "signature",
              "debug",
              "document-crop",
              "document-editor",
              "signature",
              "annotate",
              "multi-annotations-selection",
              "callout",
              "ink",
              "highlighter",
            ];

        if (isMobile) toolbarItems = [...toolbarItems, "polygon", "cloudy-polygon", "polyline"];

        let newToolbarItems = PSPDFKit.defaultToolbarItems
          .filter((item) => !toolbarItems.includes(item.type))
          .map((item) => (item.type === "ellipse" ? { ...item, title: "Circle" } : item));

        if (!readOnly) {
          let searchIndex = newToolbarItems.findIndex((d) => d.type === "search");
          let spacerIndex = newToolbarItems.findIndex((d) => d.type === "spacer");

          const removedItem = newToolbarItems.splice(searchIndex, 1);
          newToolbarItems.splice(spacerIndex, 0, removedItem[0]);

          if (isMobile) {
            searchIndex = newToolbarItems.findIndex((d) => d.type === "search");

            const panIcon = `
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M21.7 9.6c.4-1.3.3-2.1-.6-2.6-1.1-.6-2 0-2.4 1l-1 3.2-.3-.1c.4-1.9.9-3.9 1.1-5.8 0-.2-.1-1.3-1-1.7-1.3-.6-2.3 0-2.5 1.6-.2 1.3-.5 3.1-.8 4.8H14s-.1-6.2-.2-6.6c-.1-.4-.3-1.7-1.8-1.7s-1.95.85-1.9 1.6c.05.75.2 6.7.2 6.7h-.2c-.4-2.1-.9-3.5-1.4-5.3 0-.3-.6-1.9-2.4-1.1-1.3.6-1.1 1.6-1 2.1.1.5 1.7 5.6 2.1 8.2l-.4.2S4.8 11.2 3.5 11c-1.4-.1-2.9 2-1.4 3.2 1.5 1.2 4 6.8 5.5 7.4 1.7.7 3.2.2 4.7.3 1.5.1 2.9.4 4.3-.5s2.6-4.9 2.6-4.9c1-2.4 2.1-5.7 2.5-6.9z"
                  fill="currentColor"
                  fill-opacity=".01"
                  stroke="currentColor"
                  stroke-width="1.1"
                  stroke-miterlimit="10"
                  stroke-linejoin="round"
                ></path>
              </svg>
            `;

            newToolbarItems.splice(searchIndex, 0, {
              type: "custom",
              id: "custom-pan-tool",
              title: "Custom Pan Mode",
              icon: panIcon,
              selected: false,
              onPress: () => {
                pdfViewer.setToolbarItems((items) => {
                  return items.map((t) => {
                    return t.id === "custom-pan-tool" ? { ...t, selected: !t.selected } : t;
                  });
                });

                pdfViewer.setViewState((viewState) =>
                  viewState.set(
                    "interactionMode",
                    pdfViewer.viewState.interactionMode === null ? PSPDFKit.InteractionMode.PAN : null
                  )
                );
              },
            });
          }

          newToolbarItems = newToolbarItems.map((item) => {
            switch (item.type) {
              case "ink":
              case "text-highlighter":
              case "highlighter":
                item = { ...item, dropdownGroup: "drawing-group" };
                break;
              case "line":
              case "polyline":
              case "polygon":
              case "cloudy-polygon":
                item = { ...item, dropdownGroup: "line-group" };
                break;
              case "arrow":
                item = { ...item, dropdownGroup: "arrow-group" };
                break;
              case "rectangle":
                item = { ...item, dropdownGroup: "rectangle-group" };
                break;
              case "ellipse":
                item = { ...item, dropdownGroup: "ellipse-group" };
                break;
            }

            return item;
          });

          const redInkIcon = `
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.75" d="M1.70679 20.568L3.47479 22.335L2.05979 23.75C1.87226 23.9375 1.61795 24.0428 1.35279 24.0428C1.08762 24.0428 0.833314 23.9375 0.645787 23.75L0.292786 23.396C0.105315 23.2085 0 22.9542 0 22.689C0 22.4238 0.105315 22.1695 0.292786 21.982L1.70679 20.568Z" fill="#F82400"/>
              <path d="M8.92479 5.92999L8.21479 6.62999C3.26479 11.63 3.62479 16.89 3.62479 16.89L2.04479 18.48C1.92838 18.5961 1.83602 18.734 1.773 18.8859C1.70999 19.0378 1.67755 19.2006 1.67755 19.365C1.67755 19.5294 1.70999 19.6922 1.773 19.8441C1.83602 19.9959 1.92838 20.1339 2.04479 20.25L3.79479 22C3.9109 22.1164 4.04884 22.2088 4.2007 22.2718C4.35256 22.3348 4.51537 22.3672 4.67979 22.3672C4.8442 22.3672 5.00701 22.3348 5.15887 22.2718C5.31073 22.2088 5.44867 22.1164 5.56479 22L7.15479 20.41C7.15479 20.41 12.4648 20.77 17.4148 15.82L18.1148 15.11" stroke="black" stroke-miterlimit="10" stroke-linecap="round"/>
              <path opacity="0.33" d="M16.7068 15.826L8.2218 7.341L6.8078 8.755L15.2928 17.24L16.7068 15.826Z" fill="#F82400"/>
              <path opacity="0.5" d="M6.93981 19.931L4.11081 17.103L3.40381 17.81L6.23281 20.638L6.93981 19.931Z" fill="#F82400"/>
              <circle cx="18.0448" cy="6" r="6" fill="#F82400"/>
            </svg>
          `;

          const greenInkIcon = `
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.75" d="M1.70679 20.568L3.47479 22.335L2.05979 23.75C1.87226 23.9375 1.61795 24.0428 1.35279 24.0428C1.08762 24.0428 0.833314 23.9375 0.645787 23.75L0.292786 23.396C0.105315 23.2085 0 22.9542 0 22.689C0 22.4238 0.105315 22.1695 0.292786 21.982L1.70679 20.568Z" fill="#6DB000"/>
              <path d="M8.92479 5.92999L8.21479 6.62999C3.26479 11.63 3.62479 16.89 3.62479 16.89L2.04479 18.48C1.92838 18.5961 1.83602 18.734 1.773 18.8859C1.70999 19.0378 1.67755 19.2006 1.67755 19.365C1.67755 19.5294 1.70999 19.6922 1.773 19.8441C1.83602 19.9959 1.92838 20.1339 2.04479 20.25L3.79479 22C3.9109 22.1164 4.04884 22.2088 4.2007 22.2718C4.35256 22.3348 4.51537 22.3672 4.67979 22.3672C4.8442 22.3672 5.00701 22.3348 5.15887 22.2718C5.31073 22.2088 5.44867 22.1164 5.56479 22L7.15479 20.41C7.15479 20.41 12.4648 20.77 17.4148 15.82L18.1148 15.11" stroke="black" stroke-miterlimit="10" stroke-linecap="round"/>
              <path opacity="0.33" d="M16.7068 15.826L8.2218 7.341L6.8078 8.755L15.2928 17.24L16.7068 15.826Z" fill="#6DB000"/>
              <path opacity="0.5" d="M6.93981 19.931L4.11081 17.103L3.40381 17.81L6.23281 20.638L6.93981 19.931Z" fill="#6DB000"/>
              <circle cx="18.0448" cy="6" r="6" fill="#6DB000"/>
            </svg>
          `;

          const blueInkIcon = `
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.75" d="M1.70679 20.568L3.47479 22.335L2.05979 23.75C1.87226 23.9375 1.61795 24.0428 1.35279 24.0428C1.08762 24.0428 0.833314 23.9375 0.645787 23.75L0.292786 23.396C0.105315 23.2085 0 22.9542 0 22.689C0 22.4238 0.105315 22.1695 0.292786 21.982L1.70679 20.568Z" fill="#2293FB"/>
              <path d="M8.92479 5.92999L8.21479 6.62999C3.26479 11.63 3.62479 16.89 3.62479 16.89L2.04479 18.48C1.92838 18.5961 1.83602 18.734 1.773 18.8859C1.70999 19.0378 1.67755 19.2006 1.67755 19.365C1.67755 19.5294 1.70999 19.6922 1.773 19.8441C1.83602 19.9959 1.92838 20.1339 2.04479 20.25L3.79479 22C3.9109 22.1164 4.04884 22.2088 4.2007 22.2718C4.35256 22.3348 4.51537 22.3672 4.67979 22.3672C4.8442 22.3672 5.00701 22.3348 5.15887 22.2718C5.31073 22.2088 5.44867 22.1164 5.56479 22L7.15479 20.41C7.15479 20.41 12.4648 20.77 17.4148 15.82L18.1148 15.11" stroke="black" stroke-miterlimit="10" stroke-linecap="round"/>
              <path opacity="0.33" d="M16.7068 15.826L8.2218 7.341L6.8078 8.755L15.2928 17.24L16.7068 15.826Z" fill="#2293FB"/>
              <path opacity="0.5" d="M6.93981 19.931L4.11081 17.103L3.40381 17.81L6.23281 20.638L6.93981 19.931Z" fill="#2293FB"/>
              <circle cx="18.0448" cy="6" r="6" fill="#2293FB"/>
            </svg>
          `;

          const highlighterIcon = `
            <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg" focusable="false">
              <path opacity=".25" d="M13.02 16.6l-4.96-4.95 3.05-6.86 8.76 8.77-6.85 3.04z" fill="currentColor"></path>
              <path d="M12 2.81l-.46.47a3 3 0 00-.9 1.72 11.67 11.67 0 01-2.93 6.3l-1.77 1.77a1 1 0 000 1.41l4.25 4.24a1 1 0 001.41 0L13.37 17a11.64 11.64 0 016.34-3 3 3 0 001.68-.85l.46-.47" stroke="currentColor" stroke-miterlimit="10" fill="none" stroke-linecap="round"></path>
              <path opacity=".33" d="M14.075 16.242L8.42 10.585l-.707.707 5.656 5.657.707-.707z" fill="currentColor"></path>
              <path opacity=".66" d="M2.34 18.44l3.805-3.816 3.816 3.816-2.471 2.48a.89.89 0 01-1 .2l-3.92-1.58a.68.68 0 01-.414-.488.68.68 0 01.184-.612z" fill="currentColor"></path>
              <path opacity=".66" d="M2.521 18.59h.001l3.628-3.64 3.463 3.463-2.295 2.303h0l-.004.004a.64.64 0 01-.719.144h0l-.007-.003-3.92-1.58h0l-.003-.001a.43.43 0 01-.259-.306.43.43 0 01.115-.384z" stroke="currentColor" fill="none" stroke-width=".5"></path>
              <path opacity=".66" d="M2.17 18.73a.69.69 0 00.4.81l3.98 1.605a.874.874 0 00.65-.025 2.94 2.94 0 00-.9-2.71c-1.09-.83-2.71-.65-4.13.32z" fill="currentColor"></path>
            </svg>
          `;

          const redInk = {
            id: "red-drawing",
            type: "custom",
            title: "Red Drawing",
            icon: redInkIcon,
            selected: false,
            onPress: () => {
              const button = pdfViewer.contentDocument.querySelector('[title="Red Drawing"]');

              if (button.classList.contains("PSPDFKit-Toolbar-Button-active")) {
                pdfViewer.setToolbarItems((items) => {
                  return items.map((t) => {
                    return t.id === "red-drawing" ? { ...t, selected: false } : t;
                  });
                });

                button.classList.remove("PSPDFKit-Toolbar-Button-active");
                button.classList.remove("PSPDFKit-Tool-Button-active");

                button.innerHTML = `
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.75" d="M1.70679 20.568L3.47479 22.335L2.05979 23.75C1.87226 23.9375 1.61795 24.0428 1.35279 24.0428C1.08762 24.0428 0.833314 23.9375 0.645787 23.75L0.292786 23.396C0.105315 23.2085 0 22.9542 0 22.689C0 22.4238 0.105315 22.1695 0.292786 21.982L1.70679 20.568Z" fill="#F82400"/>
                    <path d="M8.92479 5.92999L8.21479 6.62999C3.26479 11.63 3.62479 16.89 3.62479 16.89L2.04479 18.48C1.92838 18.5961 1.83602 18.734 1.773 18.8859C1.70999 19.0378 1.67755 19.2006 1.67755 19.365C1.67755 19.5294 1.70999 19.6922 1.773 19.8441C1.83602 19.9959 1.92838 20.1339 2.04479 20.25L3.79479 22C3.9109 22.1164 4.04884 22.2088 4.2007 22.2718C4.35256 22.3348 4.51537 22.3672 4.67979 22.3672C4.8442 22.3672 5.00701 22.3348 5.15887 22.2718C5.31073 22.2088 5.44867 22.1164 5.56479 22L7.15479 20.41C7.15479 20.41 12.4648 20.77 17.4148 15.82L18.1148 15.11" stroke="black" stroke-miterlimit="10" stroke-linecap="round"/>
                    <path opacity="0.33" d="M16.7068 15.826L8.2218 7.341L6.8078 8.755L15.2928 17.24L16.7068 15.826Z" fill="#F82400"/>
                    <path opacity="0.5" d="M6.93981 19.931L4.11081 17.103L3.40381 17.81L6.23281 20.638L6.93981 19.931Z" fill="#F82400"/>
                    <circle cx="18.0448" cy="6" r="6" fill="#F82400"/>
                  </svg>
                `;

                pdfViewer.setViewState((viewState) => viewState.set("interactionMode", null));

                return;
              }

              if (!button.classList.contains("PSPDFKit-Toolbar-Button-active")) {
                pdfViewer.setToolbarItems((items) => {
                  return items.map((t) => {
                    return t.id === "red-drawing" ? { ...t, selected: true } : t;
                  });
                });

                button.classList.add("PSPDFKit-Toolbar-Button-active");
                button.classList.add("PSPDFKit-Tool-Button-active");

                button.innerHTML = `
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.75" d="M1.70679 20.568L3.47479 22.335L2.05979 23.75C1.87226 23.9375 1.61795 24.0428 1.35279 24.0428C1.08762 24.0428 0.833314 23.9375 0.645787 23.75L0.292786 23.396C0.105315 23.2085 0 22.9542 0 22.689C0 22.4238 0.105315 22.1695 0.292786 21.982L1.70679 20.568Z" fill="#F82400"/>
                    <path d="M8.92479 5.92999L8.21479 6.62999C3.26479 11.63 3.62479 16.89 3.62479 16.89L2.04479 18.48C1.92838 18.5961 1.83602 18.734 1.773 18.8859C1.70999 19.0378 1.67755 19.2006 1.67755 19.365C1.67755 19.5294 1.70999 19.6922 1.773 19.8441C1.83602 19.9959 1.92838 20.1339 2.04479 20.25L3.79479 22C3.9109 22.1164 4.04884 22.2088 4.2007 22.2718C4.35256 22.3348 4.51537 22.3672 4.67979 22.3672C4.8442 22.3672 5.00701 22.3348 5.15887 22.2718C5.31073 22.2088 5.44867 22.1164 5.56479 22L7.15479 20.41C7.15479 20.41 12.4648 20.77 17.4148 15.82L18.1148 15.11" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
                    <path opacity="0.33" d="M16.7068 15.826L8.2218 7.341L6.8078 8.755L15.2928 17.24L16.7068 15.826Z" fill="#F82400"/>
                    <path opacity="0.5" d="M6.93981 19.931L4.11081 17.103L3.40381 17.81L6.23281 20.638L6.93981 19.931Z" fill="#F82400"/>
                    <circle cx="18.0448" cy="6" r="6" fill="#F82400"/>
                  </svg>
                `;

                pdfViewer.setViewState((viewState) => viewState.set("interactionMode", null));

                pdfViewer.setAnnotationPresets((presets) => {
                  presets.ink = {
                    ...presets.ink,
                    lineWidth: 1,
                    opacity: 1,
                    strokeColor: PSPDFKit.Color.RED,
                  };

                  return presets;
                });

                pdfViewer.setCurrentAnnotationPreset("ink");

                pdfViewer.setViewState((viewState) => viewState.set("interactionMode", PSPDFKit.InteractionMode.INK));

                return;
              }

              pdfViewer.setViewState((viewState) => viewState.set("interactionMode", null));
            },
          };

          const greenInk = {
            id: "green-drawing",
            type: "custom",
            title: "Green Drawing",
            icon: greenInkIcon,
            onPress: () => {
              const button = pdfViewer.contentDocument.querySelector('[title="Green Drawing"]');

              if (button.classList.contains("PSPDFKit-Toolbar-Button-active")) {
                pdfViewer.setToolbarItems((items) => {
                  return items.map((t) => {
                    return t.id === "green-drawing" ? { ...t, selected: false } : t;
                  });
                });

                button.classList.remove("PSPDFKit-Toolbar-Button-active");
                button.classList.remove("PSPDFKit-Tool-Button-active");

                button.innerHTML = `
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.75" d="M1.70679 20.568L3.47479 22.335L2.05979 23.75C1.87226 23.9375 1.61795 24.0428 1.35279 24.0428C1.08762 24.0428 0.833314 23.9375 0.645787 23.75L0.292786 23.396C0.105315 23.2085 0 22.9542 0 22.689C0 22.4238 0.105315 22.1695 0.292786 21.982L1.70679 20.568Z" fill="#6DB000"/>
                    <path d="M8.92479 5.92999L8.21479 6.62999C3.26479 11.63 3.62479 16.89 3.62479 16.89L2.04479 18.48C1.92838 18.5961 1.83602 18.734 1.773 18.8859C1.70999 19.0378 1.67755 19.2006 1.67755 19.365C1.67755 19.5294 1.70999 19.6922 1.773 19.8441C1.83602 19.9959 1.92838 20.1339 2.04479 20.25L3.79479 22C3.9109 22.1164 4.04884 22.2088 4.2007 22.2718C4.35256 22.3348 4.51537 22.3672 4.67979 22.3672C4.8442 22.3672 5.00701 22.3348 5.15887 22.2718C5.31073 22.2088 5.44867 22.1164 5.56479 22L7.15479 20.41C7.15479 20.41 12.4648 20.77 17.4148 15.82L18.1148 15.11" stroke="black" stroke-miterlimit="10" stroke-linecap="round"/>
                    <path opacity="0.33" d="M16.7068 15.826L8.2218 7.341L6.8078 8.755L15.2928 17.24L16.7068 15.826Z" fill="#6DB000"/>
                    <path opacity="0.5" d="M6.93981 19.931L4.11081 17.103L3.40381 17.81L6.23281 20.638L6.93981 19.931Z" fill="#6DB000"/>
                    <circle cx="18.0448" cy="6" r="6" fill="#6DB000"/>
                  </svg>
                `;

                pdfViewer.setViewState((viewState) => viewState.set("interactionMode", null));

                return;
              }

              if (!button.classList.contains("PSPDFKit-Toolbar-Button-active")) {
                pdfViewer.setToolbarItems((items) => {
                  return items.map((t) => {
                    return t.id === "green-drawing" ? { ...t, selected: true } : t;
                  });
                });

                button.classList.add("PSPDFKit-Toolbar-Button-active");
                button.classList.add("PSPDFKit-Tool-Button-active");

                button.innerHTML = `
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.75" d="M1.70679 20.568L3.47479 22.335L2.05979 23.75C1.87226 23.9375 1.61795 24.0428 1.35279 24.0428C1.08762 24.0428 0.833314 23.9375 0.645787 23.75L0.292786 23.396C0.105315 23.2085 0 22.9542 0 22.689C0 22.4238 0.105315 22.1695 0.292786 21.982L1.70679 20.568Z" fill="#6DB000"/>
                    <path d="M8.92479 5.92999L8.21479 6.62999C3.26479 11.63 3.62479 16.89 3.62479 16.89L2.04479 18.48C1.92838 18.5961 1.83602 18.734 1.773 18.8859C1.70999 19.0378 1.67755 19.2006 1.67755 19.365C1.67755 19.5294 1.70999 19.6922 1.773 19.8441C1.83602 19.9959 1.92838 20.1339 2.04479 20.25L3.79479 22C3.9109 22.1164 4.04884 22.2088 4.2007 22.2718C4.35256 22.3348 4.51537 22.3672 4.67979 22.3672C4.8442 22.3672 5.00701 22.3348 5.15887 22.2718C5.31073 22.2088 5.44867 22.1164 5.56479 22L7.15479 20.41C7.15479 20.41 12.4648 20.77 17.4148 15.82L18.1148 15.11" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
                    <path opacity="0.33" d="M16.7068 15.826L8.2218 7.341L6.8078 8.755L15.2928 17.24L16.7068 15.826Z" fill="#6DB000"/>
                    <path opacity="0.5" d="M6.93981 19.931L4.11081 17.103L3.40381 17.81L6.23281 20.638L6.93981 19.931Z" fill="#6DB000"/>
                    <circle cx="18.0448" cy="6" r="6" fill="#6DB000"/>
                  </svg>
                `;

                pdfViewer.setViewState((viewState) => viewState.set("interactionMode", null));

                pdfViewer.setAnnotationPresets((presets) => {
                  presets.ink = {
                    ...presets.ink,
                    lineWidth: 1,
                    opacity: 1,
                    strokeColor: PSPDFKit.Color.GREEN,
                  };

                  return presets;
                });

                pdfViewer.setCurrentAnnotationPreset("ink");

                pdfViewer.setViewState((viewState) => viewState.set("interactionMode", PSPDFKit.InteractionMode.INK));

                return;
              }

              pdfViewer.setViewState((viewState) => viewState.set("interactionMode", null));
            },
          };

          const blueInk = {
            id: "blue-drawing",
            type: "custom",
            title: "Blue Drawing",
            icon: blueInkIcon,
            onPress: () => {
              const button = pdfViewer.contentDocument.querySelector('[title="Blue Drawing"]');

              if (button.classList.contains("PSPDFKit-Toolbar-Button-active")) {
                pdfViewer.setToolbarItems((items) => {
                  return items.map((t) => {
                    return t.id === "blue-drawing" ? { ...t, selected: false } : t;
                  });
                });

                button.classList.remove("PSPDFKit-Toolbar-Button-active");
                button.classList.remove("PSPDFKit-Tool-Button-active");

                button.innerHTML = `
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.75" d="M1.70679 20.568L3.47479 22.335L2.05979 23.75C1.87226 23.9375 1.61795 24.0428 1.35279 24.0428C1.08762 24.0428 0.833314 23.9375 0.645787 23.75L0.292786 23.396C0.105315 23.2085 0 22.9542 0 22.689C0 22.4238 0.105315 22.1695 0.292786 21.982L1.70679 20.568Z" fill="#2293FB"/>
                    <path d="M8.92479 5.92999L8.21479 6.62999C3.26479 11.63 3.62479 16.89 3.62479 16.89L2.04479 18.48C1.92838 18.5961 1.83602 18.734 1.773 18.8859C1.70999 19.0378 1.67755 19.2006 1.67755 19.365C1.67755 19.5294 1.70999 19.6922 1.773 19.8441C1.83602 19.9959 1.92838 20.1339 2.04479 20.25L3.79479 22C3.9109 22.1164 4.04884 22.2088 4.2007 22.2718C4.35256 22.3348 4.51537 22.3672 4.67979 22.3672C4.8442 22.3672 5.00701 22.3348 5.15887 22.2718C5.31073 22.2088 5.44867 22.1164 5.56479 22L7.15479 20.41C7.15479 20.41 12.4648 20.77 17.4148 15.82L18.1148 15.11" stroke="black" stroke-miterlimit="10" stroke-linecap="round"/>
                    <path opacity="0.33" d="M16.7068 15.826L8.2218 7.341L6.8078 8.755L15.2928 17.24L16.7068 15.826Z" fill="#2293FB"/>
                    <path opacity="0.5" d="M6.93981 19.931L4.11081 17.103L3.40381 17.81L6.23281 20.638L6.93981 19.931Z" fill="#2293FB"/>
                    <circle cx="18.0448" cy="6" r="6" fill="#2293FB"/>
                  </svg>
                `;

                pdfViewer.setViewState((viewState) => viewState.set("interactionMode", null));

                return;
              }

              if (!button.classList.contains("PSPDFKit-Toolbar-Button-active")) {
                pdfViewer.setToolbarItems((items) => {
                  return items.map((t) => {
                    return t.id === "blue-drawing" ? { ...t, selected: true } : t;
                  });
                });

                button.classList.add("PSPDFKit-Toolbar-Button-active");
                button.classList.add("PSPDFKit-Tool-Button-active");

                button.innerHTML = `
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.75" d="M1.70679 20.568L3.47479 22.335L2.05979 23.75C1.87226 23.9375 1.61795 24.0428 1.35279 24.0428C1.08762 24.0428 0.833314 23.9375 0.645787 23.75L0.292786 23.396C0.105315 23.2085 0 22.9542 0 22.689C0 22.4238 0.105315 22.1695 0.292786 21.982L1.70679 20.568Z" fill="#2293FB"/>
                    <path d="M8.92479 5.92999L8.21479 6.62999C3.26479 11.63 3.62479 16.89 3.62479 16.89L2.04479 18.48C1.92838 18.5961 1.83602 18.734 1.773 18.8859C1.70999 19.0378 1.67755 19.2006 1.67755 19.365C1.67755 19.5294 1.70999 19.6922 1.773 19.8441C1.83602 19.9959 1.92838 20.1339 2.04479 20.25L3.79479 22C3.9109 22.1164 4.04884 22.2088 4.2007 22.2718C4.35256 22.3348 4.51537 22.3672 4.67979 22.3672C4.8442 22.3672 5.00701 22.3348 5.15887 22.2718C5.31073 22.2088 5.44867 22.1164 5.56479 22L7.15479 20.41C7.15479 20.41 12.4648 20.77 17.4148 15.82L18.1148 15.11" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
                    <path opacity="0.33" d="M16.7068 15.826L8.2218 7.341L6.8078 8.755L15.2928 17.24L16.7068 15.826Z" fill="#2293FB"/>
                    <path opacity="0.5" d="M6.93981 19.931L4.11081 17.103L3.40381 17.81L6.23281 20.638L6.93981 19.931Z" fill="#2293FB"/>
                    <circle cx="18.0448" cy="6" r="6" fill="#2293FB"/>
                  </svg>
                `;

                pdfViewer.setViewState((viewState) => viewState.set("interactionMode", null));

                pdfViewer.setAnnotationPresets((presets) => {
                  presets.ink = {
                    ...presets.ink,
                    lineWidth: 1,
                    opacity: 1,
                    strokeColor: PSPDFKit.Color.BLUE,
                  };

                  return presets;
                });

                pdfViewer.setCurrentAnnotationPreset("ink");

                pdfViewer.setViewState((viewState) => viewState.set("interactionMode", PSPDFKit.InteractionMode.INK));

                return;
              }

              pdfViewer.setViewState((viewState) => viewState.set("interactionMode", null));
            },
          };

          const highlighter = {
            id: "highlighter",
            type: "custom",
            title: "Highlighter",
            icon: highlighterIcon,
            onPress: () => {
              const button = pdfViewer.contentDocument.querySelector('[title="Highlighter"]');

              if (button.classList.contains("PSPDFKit-Toolbar-Button-active")) {
                pdfViewer.setToolbarItems((items) => {
                  return items.map((t) => {
                    return t.id === "highlighter" ? { ...t, selected: false } : t;
                  });
                });

                button.classList.remove("PSPDFKit-Toolbar-Button-active");
                button.classList.remove("PSPDFKit-Tool-Button-active");

                button.innerHTML = `
                  <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg" focusable="false">
                    <path opacity=".25" d="M13.02 16.6l-4.96-4.95 3.05-6.86 8.76 8.77-6.85 3.04z" fill="currentColor"></path>
                    <path d="M12 2.81l-.46.47a3 3 0 00-.9 1.72 11.67 11.67 0 01-2.93 6.3l-1.77 1.77a1 1 0 000 1.41l4.25 4.24a1 1 0 001.41 0L13.37 17a11.64 11.64 0 016.34-3 3 3 0 001.68-.85l.46-.47" stroke="currentColor" stroke-miterlimit="10" fill="none" stroke-linecap="round"></path>
                    <path opacity=".33" d="M14.075 16.242L8.42 10.585l-.707.707 5.656 5.657.707-.707z" fill="currentColor"></path>
                    <path opacity=".66" d="M2.34 18.44l3.805-3.816 3.816 3.816-2.471 2.48a.89.89 0 01-1 .2l-3.92-1.58a.68.68 0 01-.414-.488.68.68 0 01.184-.612z" fill="currentColor"></path>
                    <path opacity=".66" d="M2.521 18.59h.001l3.628-3.64 3.463 3.463-2.295 2.303h0l-.004.004a.64.64 0 01-.719.144h0l-.007-.003-3.92-1.58h0l-.003-.001a.43.43 0 01-.259-.306.43.43 0 01.115-.384z" stroke="currentColor" fill="none" stroke-width=".5"></path>
                    <path opacity=".66" d="M2.17 18.73a.69.69 0 00.4.81l3.98 1.605a.874.874 0 00.65-.025 2.94 2.94 0 00-.9-2.71c-1.09-.83-2.71-.65-4.13.32z" fill="currentColor"></path>
                  </svg>
                `;

                pdfViewer.setViewState((viewState) => viewState.set("interactionMode", null));

                return;
              }

              if (!button.classList.contains("PSPDFKit-Toolbar-Button-active")) {
                pdfViewer.setToolbarItems((items) => {
                  return items.map((t) => {
                    return t.id === "highlighter" ? { ...t, selected: true } : t;
                  });
                });

                button.classList.add("PSPDFKit-Toolbar-Button-active");
                button.classList.add("PSPDFKit-Tool-Button-active");

                button.innerHTML = `
                  <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg" focusable="false">
                    <path opacity=".25" d="M13.02 16.6l-4.96-4.95 3.05-6.86 8.76 8.77-6.85 3.04z" fill="white"></path>
                    <path d="M12 2.81l-.46.47a3 3 0 00-.9 1.72 11.67 11.67 0 01-2.93 6.3l-1.77 1.77a1 1 0 000 1.41l4.25 4.24a1 1 0 001.41 0L13.37 17a11.64 11.64 0 016.34-3 3 3 0 001.68-.85l.46-.47" stroke="white" stroke-miterlimit="10" fill="none" stroke-linecap="round"></path>
                    <path opacity=".33" d="M14.075 16.242L8.42 10.585l-.707.707 5.656 5.657.707-.707z" fill="white"></path>
                    <path opacity=".66" d="M2.34 18.44l3.805-3.816 3.816 3.816-2.471 2.48a.89.89 0 01-1 .2l-3.92-1.58a.68.68 0 01-.414-.488.68.68 0 01.184-.612z" fill="currentColor"></path>
                    <path opacity=".66" d="M2.521 18.59h.001l3.628-3.64 3.463 3.463-2.295 2.303h0l-.004.004a.64.64 0 01-.719.144h0l-.007-.003-3.92-1.58h0l-.003-.001a.43.43 0 01-.259-.306.43.43 0 01.115-.384z" stroke="white" fill="none" stroke-width=".5"></path>
                    <path opacity=".66" d="M2.17 18.73a.69.69 0 00.4.81l3.98 1.605a.874.874 0 00.65-.025 2.94 2.94 0 00-.9-2.71c-1.09-.83-2.71-.65-4.13.32z" fill="white"></path>
                  </svg>
                `;

                pdfViewer.setViewState((viewState) => viewState.set("interactionMode", null));

                pdfViewer.setAnnotationPresets((presets) => {
                  presets.ink = {
                    ...presets.ink,
                    lineWidth: 17,
                    opacity: 0.5,
                    strokeColor: PSPDFKit.Color.GREEN,
                  };

                  return presets;
                });

                pdfViewer.setCurrentAnnotationPreset("ink");

                pdfViewer.setViewState((viewState) => viewState.set("interactionMode", PSPDFKit.InteractionMode.INK));

                return;
              }

              pdfViewer.setViewState((viewState) => viewState.set("interactionMode", null));
            },
          };

          spacerIndex = newToolbarItems.findIndex((d) => d.type === "spacer");
          newToolbarItems.splice(spacerIndex + 1, 0, redInk);
          newToolbarItems.splice(spacerIndex + 2, 0, greenInk);
          newToolbarItems.splice(spacerIndex + 3, 0, blueInk);
          newToolbarItems.splice(spacerIndex + 4, 0, highlighter);
        }

        pdfViewer.setToolbarItems(newToolbarItems);

        const currentPageIndex = localStorage.getItem("pdf-page-index");

        pdfViewer.setViewState(
          new PSPDFKit.ViewState({
            enableAnnotationToolbar: true,
            readOnly,
            sidebarWidth: 300,
            zoom: pdfViewer.currentZoomLevel,
            currentPageIndex: currentPageIndex ? parseInt(currentPageIndex) : 0,
          })
        );
      }
    } catch (error) {
      console.log("%c Line:402 🥝 error", "color:#42b983", error);
      console.log("Something went wrong");
    }
  }, [readOnly]);

  return <div className={container()} ref={containerRef} />;
};

const container = () => css`
  width: 100%;
  height: 100%;
`;

export default memo(PDFViewer);
