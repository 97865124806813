import React, { Component, createRef } from "react";
import { css } from "emotion";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TinyAnimate from "TinyAnimate";
import { notification } from "antd";
import { chunk } from "lodash";
import { UploadIcon, DeleteIcon } from "mdi-react";

// ACTIONS
import { hideModalPage2nd, showModalPage2nd } from "../../actions/uiActions";

// Components
import InlineSpinner from "../ui/InlineSpinner";
import ListItem from "../ui/ListItem";

// Utilities
import req from "../../utilities/request-utility";
import getFileExtension from "../../utilities/getFileExtension";

// Styles
import colors from "../../style/colors";
import common from "../../style/common";

class ImageUploadMultiple extends Component {
  constructor(props) {
    super(props);

    this.inputFile = createRef();

    this.state = {
      files: [],
      prevDeletedFilesLength: 0,
      imageSliderRef: React.createRef(),
      lastIndex: 0,
      imagePreviewHeightMultiplier: 1,
      isUploading: false,
    };
  }

  componentDidMount() {
    this.props.uploadedFiles && this.getUploadedFiles();
  }

  componentDidUpdate(prevProps, prevState) {
    const { files, isUploading, prevDeletedFilesLength } = this.state;

    const { uploadedFiles: uploadedFilesPrev = [] } = prevProps;
    const { getUploadState, isTabletPortrait, isIpadMiniLandscape, uploadedFiles = [] } = this.props;

    // uploadedFiles reset
    if (uploadedFilesPrev.length !== 0 && uploadedFiles.length === 0) {
      this.inputFile.current.value = "";
      this.setState({ files: [], lastIndex: 0 });
    }

    const deletedFiles = files.filter((d) => d.status === "deleted");

    if (
      prevState.files.length !== files.length ||
      prevDeletedFilesLength !== deletedFiles.length ||
      prevProps.isTabletPortrait !== isTabletPortrait ||
      prevProps.isIpadMiniLandscape !== isIpadMiniLandscape
    ) {
      if (isTabletPortrait) {
        const chunkedFiles = chunk(
          files.filter((d) => d.status !== "deleted"),
          4
        );

        this.setState({
          imagePreviewHeightMultiplier: chunkedFiles.length,
          prevDeletedFilesLength: deletedFiles.length,
        });
      } else if (isIpadMiniLandscape) {
        const chunkedFiles = chunk(
          files.filter((d) => d.status !== "deleted"),
          6
        );

        this.setState({ imagePreviewHeightMultiplier: chunkedFiles.length, prevDeletedFilesLength: deletedFiles.length });
      } else if (!isTabletPortrait && !isIpadMiniLandscape) {
        const chunkedFiles = chunk(
          files.filter((d) => d.status !== "deleted"),
          7
        );

        this.setState({ imagePreviewHeightMultiplier: chunkedFiles.length, prevDeletedFilesLength: deletedFiles.length });
      }
    }

    if (isUploading !== prevState.isUploading && getUploadState) getUploadState(isUploading);
  }

  // create list of objects
  getUploadedFiles = () => {
    let uploadedFiles = this.props.uploadedFiles.map((x) =>
      Object.assign(
        {},
        {
          status: "uploaded",
          image: x.image,
          originalImage: x.originalImage,
          deleteToken: x.deleteToken ? x.deleteToken : undefined,
        }
      )
    );

    // Assign uploaded files to files-object
    if (uploadedFiles.length > 0) {
      const files = [...this.state.files];
      const updated = files.concat(uploadedFiles);

      this.setState({ files: updated, lastIndex: updated.length }, () => {});
    }
  };

  onFileSelect = async (e) => {
    // Check if file is selected
    if (e.target.files.length === 0) return; // No selected file

    const { lastIndex } = this.state;

    let index = lastIndex;
    for (const file of e.target.files) {
      // Check file size
      if (file.size > 200000000) {
        return notification.error({
          duration: 7,
          message: "FAILED",
          description: "The file you are trying to upload is too big. Choose a smaller file",
        });
      } else if (file.size > 39999900) {
        // TO DO! Reimplement with dialog, once that is implemented
        return notification.error({
          duration: 7,
          message: "FAILED",
          description:
            "You're about to upload a rather large file. This might take some time. Consider finding some decent Wi-Fi.",
        });
      }

      // Construct formData with file
      let formData = new FormData();
      formData.append("file", file);

      await this.setState({
        files: [...this.state.files, { status: "uploading", progress: 0 }],
      });

      // Start upload and set state
      await this.uploadFile(formData, index);

      index++;

      await this.setState({ lastIndex: index });
    }

    // Scroll upload div into view
    setTimeout(this.scrollImagePreviewToRight, 100);
  };

  uploadFile = async (file, index) => {
    try {
      await this.setState({ isUploading: true });

      const { data } = await req().post(`${this.props.urlPrefix || ""}/images/${this.props.selectedProject.number}`, file, {
        onUploadProgress: (e) => this.onUploadProgress(e, index),
      });

      if (data) this.onFileUploadSuccess(data, index);
    } catch (error) {
      this.onFileUploadFailure(error, index);
    }
  };

  deleteFile = (index) => {
    let files = [...this.state.files];
    files[index].status = "deleted";
    this.setState({ files, lastIndex: files.length });
    this.props.onFileUpdate(this.state.files.filter((f) => f.status === "uploaded"));
  };

  onUploadProgress = (progress, index) => {
    let progressPercent = (progress.loaded / progress.total) * 100;
    let files = [...this.state.files];
    files[index].progress = progressPercent;
    this.setState({
      files: files,
    });
  };

  onFileUploadSuccess = (res, index) => {
    let files = [...this.state.files];

    files[index] = {
      status: "uploaded",
      image: res.image,
      originalImage: res.originalImage,
      deleteToken: "deleteable",
    };

    this.setState({ files, isUploading: false });
    this.props.onFileUpdate(this.state.files.filter((f) => f.status === "uploaded"));
  };

  onFileUploadFailure = (err, index) => {
    notification.error({
      duration: 7,
      message: "FAILED",
      description: (
        <span>
          Could not upload your image. Try again or contact{" "}
          <a style={{ color: "#000", textDecoration: "underline" }} href="mailto:semcompletion@semcomaritime.com">
            semcompletion@semcomaritime.com
          </a>
        </span>
      ),
    });

    if (this.state.files[index]) {
      let files = [...this.state.files];
      files[index].status = "error";
      this.setState({ files });
    }

    this.setState({ isUploading: false });
  };

  scrollImagePreviewToRight = () => {
    if (this.props.disablePreview) return null;

    let { imageSliderRef } = this.state;

    if (!imageSliderRef.current) return null;

    TinyAnimate.animate(
      imageSliderRef.current.scrollLeft,
      imageSliderRef.current.scrollWidth - imageSliderRef.current.getBoundingClientRect().width,
      300,
      (x) => {
        imageSliderRef.current.scrollLeft = x;
      },
      "easeInOutQuart"
    );
  };

  render() {
    let { placeholder, primaryColor, style, boxPadding, disablePreview, hideModalPage2nd, showModalPage2nd } = this.props;
    let { files, imagePreviewHeightMultiplier, imageSliderRef } = this.state;

    const imageFiles = files
      .map((d, i) => ({ ...d, index: i }))
      .filter((d) => getFileExtension(d.image).extension !== "pdf");
    const pdfFiles = files
      .map((d, i) => ({ ...d, index: i }))
      .filter((d) => getFileExtension(d.image).extension === "pdf")
      .map((d) => ({ ...d, ...getFileExtension(d.image) }));

    return (
      <div
        className={componentStyle(primaryColor, boxPadding, imagePreviewHeightMultiplier) + " " + this.props.className}
        style={style}
      >
        <label style={{ fontWeight: 400 }}>
          <UploadIcon />
          {placeholder}
          <input
            accept="image/*,application/pdf"
            multiple={true}
            onChange={this.onFileSelect}
            type="file"
            ref={this.inputFile}
          />
        </label>
        {/* Image preview in post-like carousel */}
        {!disablePreview && files.filter((f) => f.status === "uploading" || f.status === "uploaded").length > 0 && (
          <>
            <div className="image-preview-container">
              <div className="scroll-hider" ref={imageSliderRef}>
                {imageFiles.map((d) => {
                  if (d.status === "uploading") {
                    return (
                      <div className="image-preview" key={`${d.index}-uploading-img`}>
                        <InlineSpinner title={d.progress < 100 ? Math.round(d.progress) + "%" : "Uploading file..."} />
                        <div className="progress-bar-container">
                          <div style={{ flexGrow: d.progress || 0 }} className="progress-bar colored" />
                          <div style={{ flexGrow: 100 - (d.progress || 0) }} className="progress-bar transparent" />
                        </div>
                      </div>
                    );
                  }
                  if (d.status === "uploaded") {
                    return (
                      <div className="image-preview" key={`${d.index}-uploaded-img`}>
                        <img src={d.image} alt="" onLoad={this.scrollImagePreviewToRight} />
                        <DeleteIcon onClick={() => this.deleteFile(d.index)} />
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
            <div>
              <div className="scroll-hider" ref={imageSliderRef}>
                {pdfFiles.map((d) => {
                  if (d.status === "uploading") {
                    return (
                      <div className="image-preview" key={`${d.index}-uploading-pdf`}>
                        <InlineSpinner title={d.progress < 100 ? Math.round(d.progress) + "%" : "Uploading file..."} />
                        <div className="progress-bar-container">
                          <div style={{ flexGrow: d.progress || 0 }} className="progress-bar colored" />
                          <div style={{ flexGrow: 100 - (d.progress || 0) }} className="progress-bar transparent" />
                        </div>
                      </div>
                    );
                  }
                  if (d.status === "uploaded") {
                    return (
                      <ListItem
                        clickable={true}
                        iconRight={<DeleteIcon onClick={() => this.deleteFile(d.index)} />}
                        key={`${d.index}-uploaded-pdf`}
                        title={d.displayFileName}
                      />
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const previewHeight = 150;
const componentHeight = 40;

const componentStyle = (primaryColor, boxPadding, imagePreviewHeightMultiplier) => css`
  font-size: 1rem;
  color: ${colors.black};

  /* Upload button/Input */
  label {
    display: flex !important;
    align-items: center;
    height: ${componentHeight}px;
    background: ${colors.white};
    border: 1px ${colors.midGrey} solid;
    color: ${colors.black};
    font-family: ${common.fontStack};
    margin-bottom: ${boxPadding};
    border-radius: 3px;

    svg {
      position: relative;
      width: ${componentHeight}px;
      height: ${componentHeight}px;
      margin-right: 0.45rem;
      padding: 0.45rem;
      color: ${colors.darkGrey};
      border-right: 1px solid ${colors.midGrey};
    }
  }

  input[type="file"] {
    display: none;
  }

  .image-preview-container {
    // height: ${previewHeight * imagePreviewHeightMultiplier}px;
    overflow: visible;
    margin: 0 -${boxPadding};
    margin-bottom: 20px;

    .scroll-hider {
      padding: 0 ${boxPadding};
      // height: ${previewHeight * imagePreviewHeightMultiplier}px;
      white-space: pre-wrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

    .image-preview {
      width: 150px;
      white-space: initial;
      position: relative;
      vertical-align: top;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      height: 150px;
      min-width: 150px;
      max-width: 80vw;
      border: 1px ${colors.midGrey} solid;
      border-radius: 3px;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;

      img {
        width: 150%;
      }

      &:last-child {
        margin-right: 0;
      }

      /* Delete icon */
      svg {
        position: absolute;
        top: 0;
        right: 0;
        width: 2rem;
        height: 2rem;
        padding: 0.25rem;
        border-bottom-left-radius: 4px;
        color: ${colors.darkGrey};
        border: 1px solid ${colors.midGrey};
        background-color: ${colors.white};
      }
    }
  }
  /* Progress bar */
  .progress-bar-container {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 3px;
    display: flex;
    justify-content: space-between;

    .progress-bar {
      height: 3px;
    }

    .progress-bar.colored {
      background-color: ${primaryColor};
    }
  }
`;
const mapStateToProps = (state) => ({
  primaryColor: state.appConfig.primaryColor,
  selectedProject: state.semcompletion.selectedProject,
});

const mapDispatchToProps = (dispatch) => ({
  hideModalPage2nd: bindActionCreators(hideModalPage2nd, dispatch),
  showModalPage2nd: bindActionCreators(showModalPage2nd, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploadMultiple);
