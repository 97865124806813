import React from "react";
import { css } from "emotion";
import tinycolor from "tinycolor2";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styleTypeNormalizer from "../../utilities/style-type-normalizer";
import InlineSpinner from "./InlineSpinner";
import colors from "../../style/colors";

/** Generic button component to be used across our application.
 * @param {*} props
 * @param {string} props.styleType - The type of button. Supported types: `neutral|error|success|secondary`
 * @param {boolean} props.disabled - Makes the button disabled
 * @param {boolean} props.active - Shows a spinner inside the button
 * @param {function} props.onClick - Main event handler
 * @param {string} props['data-test-id'] - Test id
 * @param {reactElement} props.iconLeft - DEPRECATION WARNING! May be removed! See examples. Icon to display left of the title
 * @param {reactElement} props.iconRight - DEPRECATION WARNING! May be removed! See examples. Icon to display right of the title
 * @param {string} props.title - DEPRECATION WARNING! May be removed! See examples. Title of the button
 *
 * Previously we used props to control title and icons but have since opted in for using
 * the children prop, making it way more flexible.
 *
 * @example
 * ```jsx static
 * // Current usage
 * <Button>
 *   <TickIcon />
 *   Terminate
 * </Button>
 *
 *
 * // LEGACY USAGE! DONT DO THIS GOING FORWARD!
 * <Button
 *   iconLeft={<TickIcon style={{ marginRight: "0.5rem" }} />}
 *   title={"Terminate"}
 * />
 * ```
 */
const Button = (props) => (
  <button
    id={props.id}
    className={`${style(props)} ${props.buttonType || ""} ${styleTypeNormalizer(props.styleType)} ${
      props.active ? "active" : ""
    } ${props.disabled ? "disabled" : ""}`}
    style={props.style}
    disabled={props.disabled ? props.disabled : false}
    onClick={!props.active ? props.onClick : null}
    data-test-id={props["data-test-id"]}
  >
    {/* Left icon (is hidden if button is active) */}
    {!props.active && props.iconLeft}

    {/* Main content OR spinner */}
    {props.active ? (
      <InlineSpinner
        color={props.buttonType === "secondary" ? props.primaryColor : colors.white}
        size={24}
        style={{ marginTop: "-4px", marginBottom: "-10px" }}
      />
    ) : (
      <>
        {props.title || ""}
        {props.children && typeof props.children === "function" && props.children()}
        {props.children && typeof props.children === "object" && props.children}
        {props.children && typeof props.children === "string" && props.children}
      </>
    )}

    {/* Icon right (is hidden if button is active) */}
    {!props.active && props.iconRight}
  </button>
);

const style = (props) => css`
  display: block;
  width: ${props.fullWidth !== false ? "100%" : props.width ? props.width : "auto"};
  border: none;
  border-radius: 3px;
  font-size: 1rem;
  padding: 0.4rem 1.5rem;
  transition: background-color 120ms ease;

  &:focus {
    outline: 0;
  }

  &.neutral {
    font-weight: 700;
    background-color: ${props.primaryColor};
    color: ${colors.white};
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    border-bottom: 3px solid ${tinycolor(props.primaryColor).darken(15).toString()};

    &:active {
      border-top: 3px solid transparent;
      background-color: ${tinycolor(props.primaryColor).darken(10).toString()};
      border-bottom: 0px solid ${tinycolor(props.primaryColor).darken(10).toString()};
    }

    &:focus {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    }

    &.disabled {
      transition: all 120ms ease;
      background-color: ${colors.midGrey};
      border-bottom: 3px solid ${tinycolor(colors.midGrey).darken(15).toString()};
    }
  }

  &.ok {
    font-weight: 700;
    background-color: ${colors.green};
    color: ${colors.white};
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    border-bottom: 3px solid ${tinycolor(colors.green).darken(15).toString()};

    &:active,
    &.active {
      border-top: 3px solid transparent;
      background-color: ${tinycolor(colors.green).darken(10).toString()};
      border-bottom: 0px solid ${tinycolor(colors.green).darken(10).toString()};
    }
  }

  &.error {
    font-weight: 700;
    background-color: ${colors.red};
    color: ${colors.white};
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    border-bottom: 3px solid ${tinycolor(colors.red).darken(15).toString()};

    &:active,
    &.active {
      border-top: 3px solid transparent;
      background-color: ${tinycolor(colors.red).darken(10).toString()};
      border-bottom: 0px solid ${tinycolor(colors.red).darken(10).toString()};
    }
  }

  &.secondary {
    color: ${colors.darkGrey};
    background-color: transparent;
    border: 1px ${colors.midGrey} solid;
    text-shadow: 0 0 0 transparent;
    transition: background-color 180ms ease;
    transition-delay: 240ms;

    &:active {
      background-color: ${colors.lightGrey};
      border: 1px ${colors.midGrey} solid;
      transition: background-color 80ms ease;
    }

    &:focus {
      border: 1px ${props.primaryColor} solid;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    }
  }
`;

const mapStateToProps = (state) => ({
  primaryColor: state.appConfig.primaryColor,
});

export default connect(mapStateToProps)(Button);

Button.propTypes = {
  /** The id to use for testing. Is written as data-test-id="prop" to the DOM */
  "data-test-id": PropTypes.string,
  /** Describes what type of button to use, primary,secondary etc. */
  buttonType: PropTypes.string,
  /** Used for displaying neutral/error/Ok events on the button */
  styleType: PropTypes.string,
  /** Used for handling submitting/creation, for example set this =true when waiting for response on network request */
  active: PropTypes.bool,
  /** Overriding of style in component, if you have very specific needs */
  style: PropTypes.object,
  /** The function which is called when the button is clicked */
  onClick: PropTypes.func,
  /** The svg icon used on the button, this is optional */
  iconLeft: PropTypes.object,
};
