import req from "../utilities/request-utility";

import { GET_PAGES, SET_TRANSITION_DIRECTION, GET_PAGES_SUCCESS, GET_PAGES_FAILURE } from "./actionTypes";

export function getPages() {
  return async (dispatch) => {
    dispatch({ type: GET_PAGES });

    try {
      const [{ data: pages }, { data: allPages }] = await Promise.all([req().get("/pages"), req().get("/pages/all")]);
      dispatch({ type: GET_PAGES_SUCCESS, payload: { allPages, pages } });
    } catch (err) {
      dispatch({ type: GET_PAGES_FAILURE });
    }
  };
}

export function setTransitionModeToForward() {
  return {
    type: SET_TRANSITION_DIRECTION,
    payload: "forward",
  };
}

export function setTransitionModeToBackward() {
  return {
    type: SET_TRANSITION_DIRECTION,
    payload: "backward",
  };
}
