// Libs
import React, { Component, Suspense } from "react";
import { Route, Switch } from "react-router";
import queryString from "query-string";
import { Router } from "react-router-dom";
import { connect } from "react-redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { bindActionCreators } from "redux";
import { css } from "emotion";
import { notification } from "antd";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Redux actions
import { checkToken } from "./actions/authActions";
import { getAppConfig } from "./actions/appConfigActions";
import { getLanguage } from "./actions/languageActions";
import { setSelectedLibraryPath, setSelectedManageLibraryPath } from "./actions/libraryActions";

// Components
import FrontPage from "./components/FrontPage";
import InstallationFlow from "./components/installationFlow/InstallationFlow";
import LoginPage from "./components/login/LoginPage";
import NavigationList from "./components/NavigationList";
import Profile from "./components/profile/Profile";
import SideMenu from "./components/SideMenu";

// Semcompletion
import SemcompletionOverview from "./components/semcompletion/SemcompletionOverviewLazy";
import PunchOverview from "./components/semcompletion/punch/PunchOverview";
import PunchSingle from "./components/semcompletion/punch/PunchSingle";
import JobPackage from "./components/semcompletion/JobPackageLazy";
import TestSheet from "./components/semcompletion/TestSheetLazy";
import Search from "./components/semcompletion/SearchLazy";
import SemcompltionProjectChooser from "./components/semcompletion/SemcompletionProjectChooser";
import SemcompletionInitializer from "./components/semcompletion/SemcompletionInitializer";

// Semcompletion Admin
import UsersOverview from "./components/semcompletion/admin/users/Overview";
import ManagePunchList from "./components/semcompletion/admin/managePunchList/Overview";
import ManageLibrary from "./components/semcompletion/admin/manageLibrary/Overview";

// Redmark Options
import ManageEquipments from "./components/semcompletion/redmarkOptions/manageEquipments/Overview";
import ManageCable from "./components/semcompletion/redmarkOptions/manageCables/Overview";

// Wire Diagrams
import WireDiagrams from "./components/semcompletion/wireDiagram/Overview";

// Library
import Library from "./components/semcompletion/library/Overview";

// UI Components
import ContextMenu from "./components/ui/ContextMenu";
import Dialog from "./components/ui/Dialog";
import LoadingPage from "./components/ui/LoadingPage";
import ModalPage from "./components/ui/ModalPage";
import ModalPage2 from "./components/ui/ModalPage2";
import Lightbox from "./components/ui/Lightbox";
import SkeletonPage from "./components/ui/SkeletonPage";

// Utilities
import history from "./utilities/navigation-history";

// actions
import { showDialog } from "./actions/uiActions";

// icons
import { AlertDecagramIcon } from "mdi-react";

import "antd/dist/antd.css";
import "./global.css";

notification.config({ maxCount: 1 });

const theme = createTheme({
  palette: {
    primary: {
      main: "#103447",
      contrastText: "#ffffff",
    },
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isStandalone: false,
      isAndroid: false,
      isIOS: false,
      showInstallationGuide: false,
    };
  }

  componentDidMount() {
    this.checkPotentialRedirect();
    this.props.getAppConfig();
    this.props.checkToken();
    this.findUserAgent();
    this.detectStandalone();
    document.querySelector(".hardcoded-spinner").remove();

    // Check if app i installed to home screen. Otherwise show dialog to prompt user to install it
    setTimeout(() => {
      if (process.env.NODE_ENV === "development") return;

      // Get potential user from query-string
      let queryStrings = queryString.parse(window.location.search);

      // Cancels installation guide
      if (Number(queryStrings.ignoreInstallationGuide) === 1 || String(queryStrings.ignoreInstallationGuide) === "true")
        return;

      if (!this.state.isStandalone && (this.state.isIOS || this.state.isAndroid) && this.props.auth.isAuthenticated) {
        // If app is not installed and the device is either ios or android but the user is logged in, show installation help prompt
        this.displayAddToHomeScreenDialog();
      } else if (
        (!this.state.isStandalone && (this.state.isIOS || this.state.isAndroid) && !this.props.auth.isAuthenticated) ||
        queryStrings.showInstallationScreen
      ) {
        // If app is not installed and the device is either ios or android or a querystring is present in url and the
        // user is not logged in, show installation guide
        this.setState({ showInstallationGuide: true });
      }
    }, 0);
  }

  checkPotentialRedirect() {
    let { hostname, pathname } = window.location;

    // Redirects from root to semcompletion
    if (
      (hostname === "semcompletion.com" ||
        hostname === "test-semcompletion.com" ||
        hostname === "semcompletion-web-service-d.azurewebsites.net" ||
        hostname === "semcompletion-web-service-t.azurewebsites.net" ||
        hostname === "semcompletion-web-service-p.azurewebsites.net" ||
        hostname === "semcompletionapp-dev.semcomaritime.com" ||
        hostname === "semcompletionapp-test.semcomaritime.com" ||
        hostname === "semcompletionapp.semcomaritime.com") &&
      (pathname === "" || pathname === "/")
    ) {
      window.location.replace(`https://${window.location.host}/semcompletion`);
    }
  }

  // Denne function er oplagt til at lave som custom hook med state variablerne isIOS og isAndroud. Det kræver bare at App classen skrives om til et Function component
  findUserAgent() {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    this.setState({
      isIOS: userAgent.match(/iPad|iPhone|iPod/) !== null ? true : false,
    });
    this.setState({
      isAndroid: userAgent.match(/Android/) !== null ? true : false,
    });
  }

  detectStandalone() {
    this.setState({
      isStandalone: window.matchMedia("(display-mode: standalone)").matches,
    });
  }

  displayAddToHomeScreenDialog() {
    let { lang, showDialog } = this.props;

    showDialog({
      icon: <AlertDecagramIcon />,
      title: lang.installationHeyThere,
      content: lang.dialogNotAddedToHomescreenContent,
      primaryActionTitle: lang.dialogNotAddedToHomescreenPrimaryAction,
      primaryAction: () => this.setState({ showInstallationGuide: true }),
      secondaryActionTitle: lang.dialogNotAddedToHomescreenSecondaryAction,
    });
  }

  closeInstallationGuide = () => {
    this.setState({ showInstallationGuide: false });
  };

  render() {
    /**
     * States:
     * ---
     * 1) User is not logged in, app is not running standalone and device is iOS or Android
     *    -> Show installation flow
     *
     * 2) User is not logged in, device is not iOS or android
     *    -> Show login screen
     *
     * 3) User is logged in (if not standalong AND ios or android also show installation-flow dialog)
     *    -> Show frontpage
     */

    const {
      transitionDirection,
      auth,
      lang,
      pages,
      appConfig,
      frontPageImage,
      isAuthenticated,
      checkingToken,
      urlSuffix,
      setSelectedLibraryPath,
      setSelectedManageLibraryPath,
    } = this.props;

    const urlSuffixArr = urlSuffix !== "" ? urlSuffix.split("/").filter((d) => d !== "") : [];

    const manageLibraryRouteComponents = [
      <Route key="sc_15_0" path={"/:appname/SemcompletionManageLibrary/:pageId"} component={ManageLibrary} />,
    ];

    const libraryRouteComponents = [
      <Route key="sc_20_0" path={"/:appname/SemcompletionLibrary/:pageId"} component={Library} />,
    ];

    let manageLibraryPath = "/:appname/SemcompletionManageLibrary/:pageId";
    let libraryPath = "/:appname/SemcompletionLibrary/:pageId";

    urlSuffixArr.map((d, i) => {
      manageLibraryPath += `/:childPageId${i + 1}`;
      manageLibraryRouteComponents.push(<Route key={`sc_15_${i + 1}`} path={manageLibraryPath} component={ManageLibrary} />);

      libraryPath += `/:childPageId${i + 1}`;
      libraryRouteComponents.push(<Route key={`sc_20_${i + 1}`} path={libraryPath} component={Library} />);
    });

    setSelectedManageLibraryPath(manageLibraryPath);
    setSelectedLibraryPath(libraryPath);

    // If some of the following content isn't available wait for it before showing the app
    // pages
    // appConfig
    // language

    // 0
    if (
      appConfig.apiUrl === "" ||
      appConfig.loading === true ||
      frontPageImage.baseURL === "" ||
      (!lang && !pages.length && !appConfig)
    ) {
      return <LoadingPage />;
    }

    // 1
    if (lang && this.state.showInstallationGuide) {
      return <InstallationFlow onCloseGuide={this.closeInstallationGuide} />;
    }

    if (checkingToken === false && isAuthenticated === false && !this.state.showInstallationGuide) {
      return <LoginPage />;
    }

    window.Rollbar.configure({
      enabled: process.env.NODE_ENV === "production" ? true : false,
      captureIp: "anonymize",
      payload: {
        person: {
          id: auth.user.id,
          username: auth.user.name,
        },
      },
    });

    return (
      <ThemeProvider theme={theme}>
        <div className={componentStyle()} data-app-root>
          <Router history={history}>
            <Route
              render={({ location }) => [
                <TransitionGroup key={0}>
                  <CSSTransition key={location.key} classNames={`page-${transitionDirection}`} timeout={240}>
                    <Suspense fallback={<SkeletonPage />}>
                      <Switch location={location}>
                        {/* general */}
                        <Route exact key={978} path="/:appname/Profile/:userId" component={Profile} />
                        <Route exact key={979} path="/:appname/NavigationList/:pageId/" component={NavigationList} />

                        {/* Default and frontpage */}
                        <Route exact key={983} path="/:appname/FrontPage" component={FrontPage} />
                        <Route exact key={983} path="/:appname/" component={FrontPage} />

                        {/************************ START OF SEMCOMPLETION ROUTES ************************/}
                        <Route
                          exact
                          key="sc_01"
                          path="/:appname/SemcompletionOverview/:pageId/:jobPackageType"
                          component={SemcompletionOverview}
                        />
                        <Route
                          exact
                          key="sc_02"
                          path="/:appname/SemcompletionOverview/:pageId/:jobPackageType/:jobPackageId"
                          component={JobPackage}
                        />

                        {/* Test sheet for MC cables */}
                        <Route
                          exact
                          key="sc_03"
                          path="/:appname/SemcompletionOverview/:pageId/:jobPackageType/:jobPackageId/:taskId/test-sheet"
                          component={TestSheet}
                        />
                        <Route
                          exact
                          key="sc_04"
                          path="/:appname/SemcompletionOverview/:pageId/:jobPackageType/:jobPackageId/:taskId/test-sheet"
                          component={TestSheet}
                        />

                        {/* Project search */}
                        <Route exact key="sc_05" path="/:appname/SemcompletionSearch/:pageId" component={Search} />

                        {/* Punch */}
                        <Route exact key="sc_06" path="/:appname/SemcompletionPunch/:pageId" component={PunchOverview} />
                        <Route exact key="sc_07" path="/:appname/SemcompletionPunch/:pageId/:id" component={PunchSingle} />

                        {/* Punch RIG */}
                        <Route exact key="sc_08" path="/:appname/SemcompletionPunchRIG/:pageId" component={PunchOverview} />
                        <Route
                          exact
                          key="sc_09"
                          path="/:appname/SemcompletionPunchRIG/:pageId/:id"
                          component={PunchSingle}
                        />

                        <Route
                          exact
                          key="sc_10"
                          path="/:appName/SemcompletionProjectChooser/:pageId/"
                          component={SemcompltionProjectChooser}
                        />

                        {/* Admin */}
                        <Route
                          exact
                          key="SemcompletionUsers"
                          path="/:appname/Semcompletionusers/:pageId"
                          component={UsersOverview}
                        />

                        <Route
                          exact
                          key="sc_14"
                          path="/:appname/SemcompletionManagePunchLists/:pageId"
                          component={ManagePunchList}
                        />

                        {manageLibraryRouteComponents}

                        {/* Redmark Options */}
                        <Route
                          exact
                          key="sc_16"
                          path="/:appname/SemcompletionManageEquipments/:pageId"
                          component={ManageEquipments}
                        />

                        <Route
                          exact
                          key="sc_18"
                          path="/:appname/SemcompletionManageCables/:pageId"
                          component={ManageCable}
                        />

                        {/* Wire Diagram */}
                        <Route
                          exact
                          key="sc_19"
                          path="/:appname/SemcompletionWireDiagrams/:pageId"
                          component={WireDiagrams}
                        />

                        {/* Library */}
                        {libraryRouteComponents}
                        {/************************ END OF SEMCOMPLETION ROUTES ************************/}
                      </Switch>
                    </Suspense>
                  </CSSTransition>
                </TransitionGroup>,
              ]}
            />
          </Router>

          {/* General UI components */}
          <SideMenu />
          <ModalPage />
          <ModalPage2 />
          <ContextMenu />
          <Dialog />
          <Lightbox />

          {pages.pages.length > 0 && !pages.loading && <SemcompletionInitializer />}
        </div>
      </ThemeProvider>
    );
  }
}

const componentStyle = () => css`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;

  p {
    margin-bottom: 0px;
  }

  .ant-radio-group {
    padding: 9px 0px;
    width: 100%;
  }

  .ant-checkbox-wrapper {
    padding: 15px 0px;
  }
`;

const mapStateToProps = (state) => ({
  transitionDirection: state.pages.transitionDirection,
  pages: state.pages,
  appConfig: state.appConfig,
  frontPageImage: state.appConfig.frontPageImage,
  auth: state.auth,
  checkingToken: state.auth.checkingToken,
  isAuthenticated: state.auth.isAuthenticated,
  lang: state.language.language,
  urlSuffix: state.library.urlSuffix,
});

const mapDispatchToProps = (dispatch) => ({
  getAppConfig: bindActionCreators(getAppConfig, dispatch),
  getLanguage: bindActionCreators(getLanguage, dispatch),
  checkToken: bindActionCreators(checkToken, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
  setSelectedLibraryPath: bindActionCreators(setSelectedLibraryPath, dispatch),
  setSelectedManageLibraryPath: bindActionCreators(setSelectedManageLibraryPath, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
